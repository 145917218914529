import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  text-align: center;
  margin: 10px 0;
`

export const Banner = styled.img.attrs((props) => ({
  src: props.src
}))`
  border-radius: 0.75rem;
  width: 100%;
`
