import { createGlobalStyle } from 'styled-components/macro'

export const GlobalFont = createGlobalStyle`
  body, button {
    font-family: ${(props) => props.theme.fontFamily},
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }
`
