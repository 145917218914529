import styled from 'styled-components/macro'

export const InvoiceLayoutWrapper = styled.div<{ fullScreenPopup?: boolean }>`
  padding: 0;
  background-color: ${(props) => props.theme.background};
  width: 100%;
  min-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  height: ${(props) => (props.fullScreenPopup ? '100%' : 'auto')};
`

export const InvoiceTitle = styled.header<{ fullScreenPopup?: boolean }>`
  width: 100%;
  background: ${(props) => props.theme.invoiceTitle.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 68px;
  padding-bottom: ${(props) => (props.fullScreenPopup ? '13px' : '0')};

  & h1 {
    color: ${(props) => props.theme.invoiceTitle.h1Color};
    font-weight: 600;
    font-style: ${(props) => props.theme.invoiceTitle.h1FontStyle};
    font-size: ${(props) => props.theme.invoiceTitle.h1FontSize};
    line-height: 40px;
    margin: 0 14px;
  }

  & h2 {
    color: ${(props) => props.theme.text};
    font-style: italic;
    font-size: 20px;
    line-height: 28px;
    margin: 0 14px;
  }
`

export const InvoiceMain = styled.main<{ fullScreenPopup?: boolean }>`
  flex-shrink: 1;
  flex-grow: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  max-width: ${(props) => (props.fullScreenPopup ? 'auto' : '400px')};
  width: ${(props) => (props.fullScreenPopup ? '100%' : 'auto')};
  height: fit-content;
  overflow-y: ${(props) => (props.fullScreenPopup ? 'hidden' : 'inherit')};
  box-sizing: content-box;
  margin: ${(props) => (props.fullScreenPopup ? '-13px 0 0' : 'auto')};
  padding: ${(props) => (props.fullScreenPopup ? '0' : '0 15px')};
  border-top-left-radius: ${(props) => (props.fullScreenPopup ? '15px' : '0')};
  border-top-right-radius: ${(props) => (props.fullScreenPopup ? '15px' : '0')};
  background: ${(props) => (props.fullScreenPopup ? props.theme.background : 'none')};
`
