import * as React from 'react'
import styled from 'styled-components/macro'
import { keyframes } from 'styled-components/macro'

const spinnerKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
}

  100% {
    transform: rotate(360deg);
}
`

const Wrapper = styled.div`
  height: 34px;
  animation: ${spinnerKeyframes} 1.5s infinite steps(12);
`

const Spinner = () => {
  return (
    <Wrapper>
      <img src="/img/ontime_spinner.svg" alt="spinner" />
    </Wrapper>
  )
}

export default Spinner
