import * as React from 'react'
import { MarketingButton } from '../../../components/buttons'
import { getI18n } from 'react-i18next'
import { Langs } from './MarketingDataFetcher'
import { useTranslation } from 'react-i18next'
import { ForwardArrowIcon } from '../../../components/ButtonIcon.component/IconSvg'
import { IconSize, IconColor } from '../../../common/const'

export interface MarketingLinkButtonProps {
  handleClick: () => void
  buttonText: Langs
}

export const MarketingLinkButton = ({ handleClick, buttonText }: MarketingLinkButtonProps) => {
  const { t } = useTranslation()
  const lang = getI18n().language as keyof Langs

  const marketingButtonText =
    buttonText === null || buttonText[lang] === undefined
      ? t('marketingLinkButtonDefault.label')
      : buttonText[lang]

  return (
    <MarketingButton onClick={handleClick}>
      {`${marketingButtonText} `}
      <ForwardArrowIcon color={IconColor.PRIMARY} size={IconSize.SMALL} />
    </MarketingButton>
  )
}
