import * as React from 'react'
import { Document, Page, PDFPageProxy } from 'react-pdf'
import { WithTranslation, withTranslation } from 'react-i18next'
import { PdfStatus } from '../../../common/const'
import { Container } from '@mui/material'
import { Placeholder } from './PdfViewer.style'

interface PdfViewerProps extends WithTranslation {
  pdfContent: string | null
  pdfStatus: PdfStatus
}

interface PdfViewerState {
  numPages: number | null
  pageNumber: number
  scale: number
}

class PdfViewerClass extends React.Component<PdfViewerProps, PdfViewerState> {
  constructor(props: PdfViewerProps) {
    super(props)
    this.state = {
      numPages: null,
      pageNumber: 1,
      scale: 1.0
    }
  }

  onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    this.setState({
      numPages: numPages
    })
  }

  onPageLoad(page: PDFPageProxy) {
    const parentDiv = document.querySelector('#pdfDocument')!
    const pageScale = parentDiv.clientWidth / page.originalWidth
    if (this.state.scale !== pageScale) {
      this.setState({ scale: pageScale })
    }
  }

  render() {
    const { t } = this.props
    if (this.props.pdfStatus === PdfStatus.OK) {
      return (
        <Container id="pdfDocument">
          <Document
            file={`data:application/pdf;base64,${this.props.pdfContent}`}
            onLoadSuccess={this.onDocumentLoadSuccess}
            error={t('inkassoPdfError.message')!}
            loading={t('inkassoPdfLoading.message')!}>
            {/* TODO: Remove magic number, handle resize */}
            {Array.from(new Array(this.state.numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                onLoadSuccess={this.onPageLoad}
                scale={this.state.scale}
                error={t('inkassoPdfError.message')!}
                loading={t('inkassoPdfLoading.message')!}
              />
            ))}
          </Document>
        </Container>
      )
    } else if (this.props.pdfStatus === PdfStatus.LOADING) {
      return <Placeholder>{t('inkassoPdfLoading.message')}</Placeholder>
    } else {
      return <Placeholder>{t('inkassoPdfError.message')}</Placeholder>
    }
  }
}

const PdfViewer = withTranslation()(PdfViewerClass)

export default PdfViewer
