import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { AxiosResponse } from 'axios'
import Spinner from './components/Spinner/Spinner.component'
import { RootState } from '../common/store'
import Invoice from '../common/types/Invoice'
import TemplateConfiguration from '../common/types/TemplateConfiguration'
import Pdf from '../common/types/Pdf'
import { setInvoiceState, updateInvoiceData } from '../common/state/invoice'
import { setLanguage, setMaintenance } from '../common/state/common'
import { setPdfStatus, setPdfData } from '../common/state/pdf'
import { clearAuth, setAuthToken } from '../common/state/auth'
import { getInvoice, getPdf } from '../common/backend'
import {
  InvoiceStatus,
  BackendInvoiceStatus,
  toInvoiceStatus,
  PdfStatus,
  InvoicerPaymentMethod,
  InvoicerAuthMethod
} from '../common/const'
import InkassoInvoice from './components/Invoice/Invoice.component'

type InvoicePageConnectedProps = ConnectedProps<typeof connector>
interface InvoicePageProps
  extends InvoicePageConnectedProps,
    WithTranslation,
    RouteComponentProps<{ invoiceId: string }> {}

class InvoicePage extends React.Component<InvoicePageProps> {
  handleInvoiceResponse(
    response: AxiosResponse<{
      status: BackendInvoiceStatus
      token: string
      invoice: Invoice
      templateConfiguration: TemplateConfiguration
      authentication: InvoicerAuthMethod
      paymentProvider: InvoicerPaymentMethod | null
      requireAuthentication: boolean
    }>
  ) {
    switch (response.data.status) {
      case BackendInvoiceStatus.OK:
        if (response.data.token) {
          this.props.setAuthToken(response.data.token)
        }
        this.props.updateInvoiceData({ status: InvoiceStatus.OK, data: response.data })
        this.setLanguageIfNeeded(response)
        break
      case BackendInvoiceStatus.EXPIRED:
        this.props.setInvoiceState(toInvoiceStatus(response.data.status))
        break
      default:
        this.props.setInvoiceState(InvoiceStatus.ERROR)
    }
  }

  handlePdfResponse(response: AxiosResponse<Pdf>) {
    if (response.data.status === BackendInvoiceStatus.EXPIRED) {
      this.props.setPdfStatus(PdfStatus.EXPIRED)
    } else {
      this.props.setPdfData(response.data)
      this.props.setPdfStatus(PdfStatus.OK)
    }
  }

  handlePdfError() {
    this.props.setPdfStatus(PdfStatus.ERROR)
  }

  // TODO: properly type error (applies also for similar code in OnTime)
  handleError(err: any) {
    if (!this.props.language) {
      if (err.response && err.response.data && err.response.data.language) {
        this.setLanguageForAuth(err.response.data.language)
      } else {
        this.setLanguageForAuth('sv')
      }
    }
    if (err.response.status === 400) {
      if (err.response.data.error === 'signicat_error') {
        this.props.setInvoiceState(InvoiceStatus.AUTH_FAILED)
      }
    } else if (err.response.status === 401) {
      if (err.response.data.subError === 'unauthorized') {
        this.props.setInvoiceState(InvoiceStatus.AUTH_NOT_ALLOWED)
      } else if (err.response.data.subError === 'failed') {
        this.props.setInvoiceState(InvoiceStatus.AUTH_FAILED)
      } else {
        // Signifies expired jwt token
        this.props.clearAuth()
        this.props.setAuthToken(null) // Clear expired token
        this.props.setInvoiceState(InvoiceStatus.AUTH_REQUIRED)
      }
    } else if (err.response.status === 403) {
      this.props.setAuthToken(null) // Clear token so redirects work
      this.props.setInvoiceState(InvoiceStatus.AUTH_REQUIRED)
    } else if (err.response.status === 503) {
      this.props.setMaintenance()
    } else {
      this.props.setInvoiceState(InvoiceStatus.ERROR)
    }
  }

  componentDidMount() {
    const invoiceId = this.props.match.params.invoiceId
    const { sessionId, jwtToken } = this.props
    if (!sessionId || !jwtToken) {
      window.location.href = `/${invoiceId}/auth`
      return
    }

    getInvoice(invoiceId, jwtToken, sessionId)
      .then((response) => this.handleInvoiceResponse(response))
      .catch((err) => this.handleError(err))
    getPdf(invoiceId, jwtToken, sessionId)
      .then((response) => this.handlePdfResponse(response))
      .catch(() => this.handlePdfError())
  }

  setLanguageIfNeeded(response: AxiosResponse<{ invoice: Invoice }>) {
    // If language has not already been set, set language from invoice lang or default
    if (!this.props.language) {
      let lang = 'sv'
      if (
        response.data &&
        response.data.invoice &&
        response.data.invoice.invoiceDetails &&
        response.data.invoice.invoiceDetails.language
      ) {
        lang = response.data.invoice.invoiceDetails.language
      }
      this.setLanguageForAuth(lang)
    }
  }

  setLanguageForAuth(lang: string) {
    this.props.i18n.changeLanguage(lang)
    this.props.setLanguage(lang)
  }

  render() {
    const { t } = this.props
    let action: 'paymentAccept' | 'paymentCancel' | 'paymentError' | undefined
    if (this.props.location.pathname.endsWith('cancel')) {
      action = 'paymentCancel'
    } else if (this.props.location.pathname.endsWith('accept')) {
      action = 'paymentAccept'
    } else if (this.props.location.pathname.endsWith('error')) {
      action = 'paymentError'
    }

    const invoiceId = this.props.match.params.invoiceId

    if (this.props.maintenance === true) {
      return <Redirect to={`/maintenance`} />
    }

    switch (this.props.invoiceState) {
      case InvoiceStatus.LOADING:
      case InvoiceStatus.NOT_LOADED:
        return <Spinner />
      case InvoiceStatus.OK:
        if (!this.props.invoiceData || !this.props.invoiceData.templateConfiguration) {
          return t('technicalProblem.label')
        }
        return (
          <InkassoInvoice
            invoiceId={invoiceId}
            invoiceObj={this.props.invoiceData}
            action={action}
          />
        )
      case InvoiceStatus.ERROR:
        return t('invoiceMissing.label')
      case InvoiceStatus.EXPIRED:
        return t('invoiceExpired.label')
      case InvoiceStatus.AUTH_REQUIRED:
      case InvoiceStatus.AUTH_PENDING:
        return <Redirect to={`/${invoiceId}/auth`} />
      case InvoiceStatus.AUTH_FAILED:
        return <Redirect to={`/${invoiceId}/auth`} />
      case InvoiceStatus.AUTH_NOT_ALLOWED:
        return <Redirect to={'/notAuthorized'} />
      default:
        return <Redirect to={`/${invoiceId}/auth`} />
    }
  }
}

const mapStateToProps = (state: RootState) => {
  let invoiceState = state.invoice.invoiceState
  // Case that we are returning from auth screen after successful auth. In this case state is already AUTH_REQUIRED. Tiny bit hacky
  if (state.invoice.invoiceState === InvoiceStatus.AUTH_REQUIRED) {
    invoiceState = InvoiceStatus.LOADING
  }

  return {
    invoiceState: invoiceState,
    invoiceData: state.invoice.invoiceData,
    language: state.common.language,
    maintenance: state.common.maintenance,
    sessionId: state.auth.sessionId,
    jwtToken: state.auth.jwtToken
  }
}

const connector = connect(mapStateToProps, {
  setInvoiceState,
  updateInvoiceData,
  setLanguage,
  setMaintenance,
  setPdfData,
  setPdfStatus,
  setAuthToken,
  clearAuth
})
export default connector(withTranslation()(InvoicePage))
