import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin: 10px -8px 0;
  background: #e8f6f9;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.text};
`
