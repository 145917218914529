import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { InvoiceRow } from '../common/invoiceElements'
import { Divider, Total, TotalLabel } from './InvoiceDetails.style'
import { InvoiceDetailsPanel } from '../common/invoiceElements'
import InvoiceDetailsCommon, { InvoiceDetailsCommonProps } from './InvoiceDetailsCommon'
import { FormatAmount } from '../../../common/formatters'
import PdfButtonIcon from '../common/PdfButtonIcon'
import DisclaimerLabel from '../DisclaimerLabel/DisclaimerLabel.component'

export interface InvoiceDetailsGeneralProps extends InvoiceDetailsCommonProps {
  total: number
  totalMax: number
  openAmount: number
  totalCreditLabel?: string
  noPdf?: boolean
  pdfLinkLabel?: string
  onShowPdf?: () => void
  isAuthenticated: boolean
}

const InvoiceDetailsGeneral = (invoiceDetailsGeneralProps: InvoiceDetailsGeneralProps) => {
  const { t } = useTranslation()
  const isAuthenticated = invoiceDetailsGeneralProps.isAuthenticated
  const invoiceCommonDetailsProps: InvoiceDetailsCommonProps = {
    detailsLabel: invoiceDetailsGeneralProps.detailsLabel,
    dueDateLabel: invoiceDetailsGeneralProps.dueDateLabel,
    dueDate: invoiceDetailsGeneralProps.dueDate,
    recipientLabel: invoiceDetailsGeneralProps.recipientLabel,
    detailsAllowCopy: invoiceDetailsGeneralProps.detailsAllowCopy,
    companyName: invoiceDetailsGeneralProps.companyName,
    accountNumberLabel: invoiceDetailsGeneralProps.accountNumberLabel,
    iban: invoiceDetailsGeneralProps.iban,
    referenceNumberLabel: invoiceDetailsGeneralProps.referenceNumberLabel,
    reference: invoiceDetailsGeneralProps.reference,
    bicLabel: invoiceDetailsGeneralProps.bicLabel,
    bic: invoiceDetailsGeneralProps.bic
  }
  const openAmountOrTotalMax =
    invoiceDetailsGeneralProps.totalMax || invoiceDetailsGeneralProps.openAmount

  return (
    <InvoiceDetailsPanel>
      <InvoiceDetailsCommon {...invoiceCommonDetailsProps} />
      <Total>
        <FormatAmount>{invoiceDetailsGeneralProps.total}</FormatAmount> €
      </Total>
      <TotalLabel>{t('ontimeInvoiceAmountDue.label')}</TotalLabel>
      {openAmountOrTotalMax && !invoiceDetailsGeneralProps.noPdf && (
        <>
          <Divider />
          <InvoiceRow size="small">
            <label>
              {t(
                invoiceDetailsGeneralProps.totalCreditLabel
                  ? 'ontimeTotalCredit.label'
                  : 'ontimeOpenInvoiceAmount.label'
              )}
            </label>
            <span>
              <FormatAmount>{openAmountOrTotalMax}</FormatAmount> €
            </span>
          </InvoiceRow>
          <InvoiceRow size="small">
            <label>{t(invoiceDetailsGeneralProps.pdfLinkLabel || 'ontimeAsPdfButton.label')}</label>
            <span>
              <PdfButtonIcon
                handleOnShowPdf={invoiceDetailsGeneralProps.onShowPdf}
                isAuthenticated={isAuthenticated}
              />
            </span>
          </InvoiceRow>
          {!isAuthenticated && (
            <InvoiceRow size="small">
              <DisclaimerLabel disclaimerText={t('pdfNotAuthenticated.label')} />
            </InvoiceRow>
          )}
        </>
      )}
    </InvoiceDetailsPanel>
  )
}

export default InvoiceDetailsGeneral
