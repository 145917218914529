import { PdfStatus } from '../const'
import { ActionReducerMapBuilder, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { authCompleted } from './auth'

interface PdfState {
  status: PdfStatus
  pdfBytes: string | null
  filename: string | null
}

const INITIAL_STATE: PdfState = {
  status: PdfStatus.NOT_LOADED,
  pdfBytes: null,
  filename: null
}

const pdfSlice = createSlice({
  name: 'pdf',
  initialState: INITIAL_STATE,
  reducers: {
    setPdfData: (
      state: Draft<PdfState>,
      action: PayloadAction<{ pdfBytes: string; filename: string }>
    ) => {
      state.status = PdfStatus.OK
      state.pdfBytes = action.payload.pdfBytes
      state.filename = action.payload.filename
    },

    setPdfStatus: (state: Draft<PdfState>, action: PayloadAction<PdfStatus>) => {
      state.status = action.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<PdfState>) => {
    //This is to prevent bounce back to invoice on path /pay -> /auth -> / -> pay
    builder.addCase(authCompleted, (state) => {
      state.status = PdfStatus.NOT_LOADED
    })
  }
})

export const { setPdfData, setPdfStatus } = pdfSlice.actions
export default pdfSlice.reducer
