import * as React from 'react'
import { Frame, Logo, Wrapper } from './BankIdLogo.style'

const BankIdLogo = ({ onLoad }: { onLoad?: () => void }) => {
  return (
    <Wrapper>
      <Frame>
        <Logo src="/img/bankid_logo.png" alt="bankid logo" onLoad={onLoad} />
      </Frame>
    </Wrapper>
  )
}

export default BankIdLogo
