import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import Grid from '@mui/material//Grid'
import { RootState } from '../../../common/store'
import InvoiceData from '../../../common/types/InvoiceData'
import { setPdfData, setPdfStatus } from '../../../common/state/pdf'
import { clearAuth } from '../../../common/state/auth'
import InvoiceDetails from '../InvoiceDetails/InvoiceDetails.component'
import PdfViewer from '../PdfViewer/PdfViewer.component'
import { SveaFooter, SveaLinks, SveaLinksMobile } from '../FooterLinks/footerLinks.component'
import { PrimaryButtonAlternate, SecondaryButton } from '../../../components/buttons'
import {
  Divider,
  DividerWrapper,
  DueDate,
  Frame,
  GridContentsWrapper,
  Header,
  InfoWrapper,
  Logo,
  LogoWrapper,
  MainFrame,
  OnlyDesktop,
  OnlyMobile,
  PdfWrapper
} from './Invoice.style'

type InkassoInvoiceConnectedProps = ConnectedProps<typeof connector>
interface InkassoInvoiceProps extends InkassoInvoiceConnectedProps, WithTranslation {
  invoiceId: string
  invoiceObj: InvoiceData
  action: 'paymentAccept' | 'paymentCancel' | 'paymentError' | undefined
}

class InkassoInvoiceClass extends React.Component<InkassoInvoiceProps, { logoLoaded: boolean }> {
  constructor(props: InkassoInvoiceProps) {
    super(props)
    this.state = { logoLoaded: false }
  }

  formatDueDate(str: string) {
    return str ? str.replace(/-/g, ' ') : ''
  }

  logout() {
    this.props.clearAuth()
    window.location.href = `/${this.props.invoiceObj.invoice.invoiceId}/auth`
  }

  render() {
    const { t } = this.props
    const invoice = this.props.invoiceObj.invoice
    const borgenar =
      invoice.customData && invoice.customData.creditor
        ? invoice.customData.creditor
        : invoice.invoicerDetails.companyName

    return (
      <MainFrame>
        <LogoWrapper>
          <OnlyDesktop>
            <Logo src="/img/Svea_logo_blue.png" alt="Svea" />
          </OnlyDesktop>
        </LogoWrapper>
        <Frame>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <GridContentsWrapper>
                <InfoWrapper>
                  <Header>
                    {t('inkassoClaimFrom.label')} {borgenar}{' '}
                  </Header>
                  <DueDate>
                    {t('inkassoDueDate.label')} {this.formatDueDate(invoice.invoiceDetails.dueDate)}
                  </DueDate>
                  <InvoiceDetails invoice={invoice} />
                  <PrimaryButtonAlternate
                    onClick={() => {
                      window.location.href = '/' + invoice.invoiceId + '/pay'
                    }}>
                    {t('inkassoPayButton.label')}
                  </PrimaryButtonAlternate>
                  <SecondaryButton onClick={() => this.logout()}>
                    {t('inkassoLogoutButton.label')}
                  </SecondaryButton>
                </InfoWrapper>
              </GridContentsWrapper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <GridContentsWrapper>
                <PdfWrapper>
                  <PdfViewer pdfContent={this.props.pdfContent} pdfStatus={this.props.pdfStatus} />
                </PdfWrapper>
              </GridContentsWrapper>
            </Grid>
          </Grid>
        </Frame>
        <OnlyMobile>
          <LogoWrapper>
            <Logo src="/img/Svea_logo_blue.png" alt="Svea" />
          </LogoWrapper>
        </OnlyMobile>
        <DividerWrapper>
          <Divider> </Divider>
        </DividerWrapper>
        <OnlyDesktop>
          <SveaLinks />
        </OnlyDesktop>
        <OnlyMobile>
          <SveaLinksMobile />
        </OnlyMobile>
        <SveaFooter />
      </MainFrame>
    )
  }
}

const InkassoInvoice = withTranslation()(InkassoInvoiceClass)

const mapStateToProps = (state: RootState) => {
  return {
    pdfContent: state.pdf.pdfBytes,
    filename: state.pdf.filename,
    pdfStatus: state.pdf.status
  }
}

const connector = connect(mapStateToProps, {
  setPdfData,
  setPdfStatus,
  clearAuth
})
export default connector(InkassoInvoice)
