import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import InvoiceLoadingSpinner from '../components/InvoiceLoadingSpinner/InvoiceLoadingSpinner.component'
import { InvoiceStatus, BackendInvoiceStatus, toInvoiceStatus, UI } from '../../common/const'
import { setInvoiceState, updateInvoiceData, setInvoiceId } from '../../common/state/invoice'
import { setAuthToken, setSessionId, setAuthError, clearAuth } from '../../common/state/auth'
import { setLanguage, setMaintenance } from '../../common/state/common'
import { getInvoice } from '../../common/backend'
import NoInvoicePage from './NoInvoice.page'
import { RootState } from '../../common/store'
import AuthError from '../components/AuthError/AuthError.component'
import InvoiceView from '../views/Invoice.view'
import { AxiosResponse } from 'axios'
import Invoice from '../../common/types/Invoice'
import { getThemeFromState } from '../../common/themes/theme'
import LinkExpiredView from '../views/LinkExpired.view'

type InvoicePageConnectedProps = ConnectedProps<typeof connector>
interface InvoicePageProps
  extends InvoicePageConnectedProps,
    WithTranslation,
    RouteComponentProps<{ invoiceId: string; sessionId?: string }> {}
class InvoicePage extends React.Component<InvoicePageProps, { themeName: string }> {
  componentDidMount() {
    const { t } = this.props
    const invoiceId = this.props.match.params.invoiceId
    this.props.setInvoiceId(invoiceId)
    const sessionId = this.props.sessionId
      ? this.props.sessionId
      : this.props.match.params.sessionId

    //console.log('--Fetching Invoice from backend--')
    //console.log('Token:' + this.props.jwtToken)
    getInvoice(invoiceId, this.props.jwtToken, sessionId)
      .then((response) => {
        switch (response.data.status) {
          case BackendInvoiceStatus.OK: //Unecessary duplicate responseJson reference for theme
            if (response.data.token) {
              this.props.setAuthToken(response.data.token)
              this.props.setSessionId(null) //SessionId is used to fetch token, and scrapped after that
            }
            this.props.updateInvoiceData({ status: InvoiceStatus.OK, data: response.data })
            this.setLanguageIfNeeded(response)
            break
          case BackendInvoiceStatus.EXPIRED:
            this.props.setInvoiceState(toInvoiceStatus(response.data.status))
            break
          default:
            console.log('Invalid invoiceState on response:' + response.data.status)
            this.props.setInvoiceState(InvoiceStatus.ERROR)
        }
      })
      .catch((err) => {
        console.log(err)
        if (!this.props.language) {
          if (err.response && err.response.data && err.response.data.language) {
            this.setLanguage(err.response.data.language)
          } else {
            this.setLanguage(getThemeFromState().languages[0])
          }
        }
        if (err.response.status === 400) {
          if (err.response.data.error === 'signicat_error') {
            this.props.setSessionId(null)
            this.props.setInvoiceState(InvoiceStatus.AUTH_FAILED)
            this.props.setAuthError(t('authorizationError.label'))
          }
        } else if (err.response.status === 401) {
          if (err.response.data.subError === 'unauthorized') {
            this.props.setInvoiceState(InvoiceStatus.AUTH_NOT_ALLOWED)
          } else if (err.response.data.subError === 'failed') {
            this.props.setSessionId(null)
            this.props.setInvoiceState(InvoiceStatus.AUTH_FAILED)
            this.props.setAuthError(t('authorizationError.label'))
          } else {
            //Signifies expired jwt token
            this.props.clearAuth()
            this.props.setAuthToken(null) //Clear expired token
            this.props.setInvoiceState(InvoiceStatus.AUTH_REQUIRED)
          }
        } else if (err.response.status === 403) {
          this.props.setAuthToken(null) //Clear token so redirects work
          this.props.setInvoiceState(InvoiceStatus.AUTH_REQUIRED)
        } else if (err.response.status === 503) {
          this.props.setMaintenance()
        } else {
          console.log('Error response from backend: ' + err)
          this.props.setInvoiceState(InvoiceStatus.ERROR)
        }
      })
  }

  setLanguage(lang: string) {
    this.props.i18n.changeLanguage(lang)
    this.props.setLanguage(lang)
  }

  setLanguageIfNeeded(response: AxiosResponse<{ invoice: Invoice }>) {
    //If language has not already been set, set language from invoice lang or default
    if (!this.props.language) {
      let lang = getThemeFromState().languages[0]
      if (
        response.data &&
        response.data.invoice &&
        response.data.invoice.invoiceDetails &&
        response.data.invoice.invoiceDetails.language
      ) {
        lang = response.data.invoice.invoiceDetails.language
      }
      this.setLanguage(lang)
    }
  }

  render() {
    //console.log('Rendering with state = ' + this.props.invoiceState)
    //console.log(this.props)
    const { t } = this.props
    let action: 'paymentCancel' | 'paymentAccept' | 'paymentError' | 'paymentRetry' | undefined
    if (this.props.location.pathname.endsWith('cancel')) {
      action = 'paymentCancel'
    } else if (this.props.location.pathname.endsWith('accept')) {
      action = 'paymentAccept'
    } else if (this.props.location.pathname.endsWith('error')) {
      action = 'paymentError'
    } else if (this.props.location.pathname.endsWith('retry')) {
      action = 'paymentRetry'
    }

    const invoiceId = this.props.match.params.invoiceId

    const sessionId = this.props.sessionId
      ? this.props.sessionId
      : this.props.match.params.sessionId

    if (this.props.maintenance === true) {
      return <Redirect to={`/maintenance`} />
    }

    switch (this.props.invoiceState) {
      case InvoiceStatus.LOADING:
      case InvoiceStatus.NOT_LOADED:
        return <InvoiceLoadingSpinner text={t('loading.label')} />
      case InvoiceStatus.OK:
        if (!this.props.invoiceData || !this.props.invoiceData.templateConfiguration) {
          return <h2 style={{ textAlign: 'center' }}>{t('technicalProblem.label')}</h2>
        }

        if (this.props.invoiceData?.requireAuthentication && (!this.props.jwtToken || !sessionId)) {
          return <Redirect to={`/${invoiceId}/auth`} />
        }

        if (this.props.invoiceData.templateConfiguration.invoiceType === UI.ONTIME) {
          return (
            <InvoiceView
              invoiceId={invoiceId}
              invoiceObj={this.props.invoiceData}
              queryParams={this.props.location.search}
              action={action}
            />
          )
        } else {
          return <p>Unsupported invoice UI</p>
        }
      case InvoiceStatus.EXPIRED:
        return <LinkExpiredView />
      case InvoiceStatus.AUTH_REQUIRED:
      case InvoiceStatus.AUTH_PENDING:
        return <Redirect to={`/${invoiceId}/auth`} />
      case InvoiceStatus.AUTH_FAILED:
        return <AuthError />
      case InvoiceStatus.ERROR:
      case InvoiceStatus.AUTH_NOT_ALLOWED:
        return <NoInvoicePage />
      default:
        return <h2 style={{ textAlign: 'center' }}>{t('technicalProblem.label')}</h2>
    }
  }
}

const mapStateToProps = (state: RootState) => {
  let invoiceState = state.invoice.invoiceState
  // Case that we are returning from auth screen after successful auth. In this case state is already AUTH_REQUIRED. Tiny bit hacky
  if (state.auth.jwtToken && state.invoice.invoiceState === InvoiceStatus.AUTH_REQUIRED) {
    invoiceState = InvoiceStatus.LOADING
  }

  return {
    invoiceState,
    invoiceData: state.invoice.invoiceData,
    jwtToken: state.auth.jwtToken,
    language: state.common.language,
    maintenance: state.common.maintenance,
    sessionId: state.auth.sessionId
  }
}

const connector = connect(mapStateToProps, {
  setInvoiceId,
  setInvoiceState,
  updateInvoiceData,
  clearAuth,
  setAuthToken,
  setLanguage,
  setMaintenance,
  setSessionId,
  setAuthError
})
export default connector(withTranslation()(InvoicePage))
