import * as React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ThemeProvider } from 'styled-components/macro'
import { RootState } from '../../../common/store'
import {
  LoggedOutLayout,
  LoggedOutMain,
  LoggedOutMessage,
  LogInButtonWrapper
} from '../LoggedOutElements/loggedOutElements.style'
import { LoggedOutLogo } from '../LoggedOutElements/loggedOutElements.component'
import { BigWarningIcon } from '../common/icons'
import { setAuthError } from '../../../common/state/auth'
import { getThemeFromState } from '../../../common/themes/theme'

type AuthErrorConnectedProps = ConnectedProps<typeof connector>
const AuthError = ({ authError, setAuthError }: AuthErrorConnectedProps) => {
  const { t } = useTranslation()
  const theme = getThemeFromState()

  return (
    <ThemeProvider theme={theme.auth}>
      <LoggedOutLayout>
        <LoggedOutMain>
          <LoggedOutLogo {...theme.logo} />
        </LoggedOutMain>
        <LoggedOutMessage>
          <BigWarningIcon alt={t('error.label')!} />
          <h2>{authError}</h2>
          <LogInButtonWrapper>
            <theme.auth.buttonComponent onClick={() => setAuthError('')}>
              {t('tryAgain.label')}
            </theme.auth.buttonComponent>
          </LogInButtonWrapper>
        </LoggedOutMessage>
      </LoggedOutLayout>
    </ThemeProvider>
  )
}

const connector = connect((state: RootState) => ({ authError: state.auth.errorMessage }), {
  setAuthError
})

export default connector(AuthError)
