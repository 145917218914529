import * as React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { removeState } from '../common/localStorage'
import { clearAuth } from '../common/state/auth'

type LogoutPageProps = ConnectedProps<typeof connector> & WithTranslation
class LogoutPage extends React.Component<LogoutPageProps> {
  componentDidMount() {
    removeState()
    this.props.clearAuth()
  }

  render() {
    const { t } = this.props
    return <h2 style={{ textAlign: 'center' }}>{t('loggedOut.label')}</h2>
  }
}

const connector = connect(null, { clearAuth })
export default connector(withTranslation()(LogoutPage))
