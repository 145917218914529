import * as React from 'react'
import Grid from '@mui/material/Grid'
import { connect, ConnectedProps } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled, { ThemeProvider } from 'styled-components/macro'
import { RootState } from '../common/store'
import { setInvoiceId } from '../common/state/invoice'
import { setAuthPending, setAuthRedirectUrl, setAuthError, startAuth } from '../common/state/auth'
import { setMaintenance } from '../common/state/common'
import backend from '../common/backend'
import MaintenancePage from './Maintenance.page'
import { PrimaryButtonAlternate } from '../components/buttons'
import BankIdLogo from './components/BankIdLogo/BankIdLogo.component'
import SveaAuthLogo from './components/SveaAuthLogo/SveaAuthLogo.component'
import {
  SveaFooter,
  SveaLinks,
  SveaLinksMobile
} from './components/FooterLinks/footerLinks.component'
import themes from '../common/themes/themes'

type AuthPageConnectedProps = ConnectedProps<typeof connector>
interface AuthPageState {
  logoLoaded: boolean
}

const MainFrame = styled.div`
  background-image: url(/img/inkasso_landing_bg.jpg);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 959px) {
    overflow-x: hidden;
    min-width: 320px;
  }
`

const OnlyDesktop = styled.div`
  @media (max-width: 959px) {
    width: 100%;
    visibility: hidden;
    height: 0;
  }

  @media not all and (max-width: 959px) {
    width: 100%;
  }
`

const OnlyMobile = styled.div`
  @media not all and (max-width: 959px) {
    width: 0;
    visibility: hidden;
    height: 0;
    margin: 0 0 0 0;
  }
`

const PopupFrameMainPage = styled.div`
  background-color: white;
  max-width: 560px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  @media (max-width: 959px) {
    background: rgba(255, 255, 255, 0);
  }
`

const CancelIconWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const CancelIcon = styled.img`
  margin: 20px;
  color: ${(props) => props.theme.heading};
  width: 24px;
  height: 24px;
`

const Divider = styled.hr`
  background-color: white;
  height: 3px;
  width: 100%;
  margin: 134px 0 60px;

  @media (max-width: 959px) {
    height: 2px;
    width: 80%;
    margin: 20px 10px 20px;
  }
`

const BankElementIdWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`

const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const InfoText = styled.div`
  width: 380px;
  color: ${(props) => props.theme.panel};
  font-size: 32px;
  font-weight: 600;
  font-style: italic;
  line-height: 1.31;
  text-align: center;
  margin-bottom: 30px;
  text-shadow: 0 0 1px ${(props) => props.theme.panel};

  @media (max-width: 959px) {
    font-size: 24px;
    color: ${(props) => props.theme.heading};
  }
`

const BankIdElement = styled.div`
  width: 380px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: 959px) {
    max-width: 380px;
    display: flex;
    min-width: 320px;
    width: 100%;
  }
`

const PrimaryButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;

  @media (max-width: 959px) {
    width: 100%;
    max-width: 335px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
  }
`

const CancelWrapper = styled.div`
  margin: 5px 0 80px 0;
  display: flex;
  align-content: center;
  justify-content: center;

  a {
    color: ${(props) => props.theme.button};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.14;
    text-decoration: none;
    border-bottom: 1px solid ${(props) => props.theme.button};
    padding-bottom: 1px;
  }
`

class AuthPage extends React.Component<
  AuthPageConnectedProps & WithTranslation & RouteComponentProps<{ invoiceId: string }>,
  AuthPageState
> {
  constructor(
    props: AuthPageConnectedProps & WithTranslation & RouteComponentProps<{ invoiceId: string }>
  ) {
    super(props)
    this.state = { logoLoaded: false }
  }

  onLogoLoad = () => {
    //console.log('Logo loaded!')
    this.setState({ logoLoaded: true })
  }

  componentDidMount() {
    this.props.setInvoiceId(this.props.match.params.invoiceId)
  }

  startAuth() {
    const { t } = this.props

    backend
      .postAuthForRedirect(this.props.match.params.invoiceId)
      .then((response) => {
        this.props.startAuth({
          sessionId: response.data.sessionId,
          authUrl: response.data.authUrl,
          authPending: true
        })
        this.props.setAuthRedirectUrl(response.data.authUrl)
      })
      .catch((err) => {
        if (err.response.status === 503) {
          this.props.setMaintenance()
        } else {
          console.log('Error response from backend: ', err)
          this.props.setAuthPending(false)
          this.props.setAuthError(t('authStartingError.label'))
        }
      })
  }

  render() {
    const { invoiceId, jwtToken, sessionId, authRedirectUrl } = this.props

    if (jwtToken && sessionId) {
      return <Redirect to={`/${invoiceId}`} />
    }

    if (process.env.REACT_APP_MAINTENANCE === 'soft') {
      return <MaintenancePage />
    }

    if (authRedirectUrl) {
      window.location.href = authRedirectUrl
      return
    }

    return (
      <ThemeProvider theme={themes.inkasso.auth}>
        <MainFrame>
          <OnlyDesktop>
            <SveaAuthLogo type="desktop" />
          </OnlyDesktop>
          <OnlyMobile>
            <SveaAuthLogo type="mobile" />
          </OnlyMobile>
          <PopupFrameMainPage>
            <OnlyDesktop>
              <CancelIconWrapper>
                <a href="https://svea.com/">
                  <CancelIcon src="/img/close.svg" />
                </a>
              </CancelIconWrapper>
            </OnlyDesktop>
            {this.BankIdElements()}
          </PopupFrameMainPage>
          <Divider />
          <OnlyDesktop>
            <SveaLinks />
          </OnlyDesktop>
          <OnlyMobile>
            <SveaLinksMobile />
          </OnlyMobile>
          <SveaFooter />
        </MainFrame>
      </ThemeProvider>
    )
  }

  BankIdElements() {
    return (
      <Grid container spacing={3} className="mainGrid">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <BankElementIdWrapper>
            <OnlyDesktop>
              <BankIdLogo onLoad={this.onLogoLoad} />
            </OnlyDesktop>
          </BankElementIdWrapper>
          <BankElementIdWrapper>{this.BankIdActionElements()}</BankElementIdWrapper>
        </Grid>
      </Grid>
    )
  }

  BankIdActionElements() {
    const { t, authPending, authError } = this.props

    if (this.state.logoLoaded) {
      return (
        <ActionWrapper>
          <InfoText>
            <OnlyDesktop>{authError || t('inkassoOpenBankId.label')}</OnlyDesktop>
          </InfoText>
          <OnlyMobile>{authError && <InfoText>{authError}</InfoText>}</OnlyMobile>
          <BankIdElement>
            <PrimaryButtonWrapper>
              <PrimaryButtonAlternate
                onClick={() => this.startAuth()}
                disabled={authPending && !authError}>
                {t('inkassoBankIdThisDevice.label')}
              </PrimaryButtonAlternate>
            </PrimaryButtonWrapper>
          </BankIdElement>
          <OnlyDesktop>
            <CancelWrapper>
              <a href="https://svea.com">{t('inkassoCancelButton.label')}</a>
            </CancelWrapper>
          </OnlyDesktop>
        </ActionWrapper>
      )
    }
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    sessionId: state.auth.sessionId,
    authPending: state.auth.authPending,
    authError: state.auth.errorMessage,
    jwtToken: state.auth.jwtToken,
    invoiceId: state.invoice.invoiceId,
    authRedirectUrl: state.auth.redirectUrl
  }
}

const connector = connect(mapStateToProps, {
  setInvoiceId,
  startAuth,
  setAuthRedirectUrl,
  setAuthPending,
  setAuthError,
  setMaintenance
})

export default connector(withTranslation()(AuthPage))
