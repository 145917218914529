import * as React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import InvoiceLayout from '../components/InvoiceLayout/InvoiceLayout.component'

const InvoiceNotFound = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  & h2 {
    font-style: italic;
    font-size: 32px;
    line-height: 40px;
    color: ${(props) => props.theme.heading};
    margin: 39px 0 0 0;
  }

  & p {
    font-style: italic;
    font-size: 16px;
    line-height: 28px;
    margin: 19px 0;
  }
`

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <InvoiceLayout>
      <InvoiceNotFound>
        <img src="/img/symbol404.svg" alt={t('sorry.label')!} />
        <h2>{t('sorry.label')}</h2>
        <p>{t('404text.label')}</p>
      </InvoiceNotFound>
    </InvoiceLayout>
  )
}

export default NotFoundPage
