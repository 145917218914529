import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Frame = styled.div`
  margin: 10px 0 30px 0;
  width: 90px;
  height: 90px;
  border-radius: 45px;
  background-color: #e4f6f9;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Logo = styled.img`
  width: 50px;
  height: 50px;
`
