import * as React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ThemeProvider } from 'styled-components/macro'
import Spinner from '../common/Spinner'
import SveaFooter from '../Footer/Footer.component'
import LoginBirthdaySelector from '../LoginBirthdaySelector/LoginBirthdaySelector.component'
import { RootState } from '../../../common/store'
import {
  LoggedOutLayout,
  LoggedOutMain,
  LogInButtonWrapper
} from '../LoggedOutElements/loggedOutElements.style'
import { LoggedOutLogo } from '../LoggedOutElements/loggedOutElements.component'
import { getThemeFromState } from '../../../common/themes/theme'
import { ForwardArrowIcon } from '../../../components/ButtonIcon.component/IconSvg'
import { IconSize, IconColor } from '../../../common/const'

type AuthBirthdayConnectedProps = ConnectedProps<typeof connector>
export interface AuthBirthdayProps extends AuthBirthdayConnectedProps {
  day: number
  month: number
  year: number
  onSetDay: (day: number) => void
  onSetMonth: (month: number) => void
  onSetYear: (year: number) => void
  onAuth?: () => void
}

const AuthBirthday = ({
  day,
  month,
  year,
  onSetDay,
  onSetMonth,
  onSetYear,
  authPending,
  onAuth
}: AuthBirthdayProps) => {
  const { t } = useTranslation()
  const theme = getThemeFromState()

  return (
    <ThemeProvider theme={theme.auth}>
      <LoggedOutLayout>
        <LoggedOutMain>
          <LoggedOutLogo {...theme.logo} />
          <LogInButtonWrapper>
            <h2>{t('birthdayEnter.label')}</h2>
            <LoginBirthdaySelector
              day={day}
              month={month}
              year={year}
              handleOnSetDay={onSetDay}
              handleOnSetMonth={onSetMonth}
              handleOnSetYear={onSetYear}
            />
            {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
            <theme.auth.buttonComponent
              onClick={onAuth || (() => {})}
              disabled={authPending || !onAuth}>
              {authPending ? (
                <Spinner />
              ) : (
                <>
                  {t('birthdayLogin.label')}
                  <ForwardArrowIcon color={IconColor.SECONDARY} size={IconSize.SMALL} />
                </>
              )}
            </theme.auth.buttonComponent>
          </LogInButtonWrapper>
        </LoggedOutMain>
        <SveaFooter />
      </LoggedOutLayout>
    </ThemeProvider>
  )
}

const connector = connect((state: RootState) => ({ authPending: state.auth.authPending }))
export default connector(AuthBirthday)
