import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { Branding } from '../const'

interface CommonState {
  language: string
  theme: Branding
  maintenance: boolean
}

const INITIAL_STATE: CommonState = {
  language: '',
  theme: Branding.NOT_FOUND,
  maintenance: false
}

const commonSlice = createSlice({
  name: 'common',
  initialState: INITIAL_STATE,
  reducers: {
    setLanguage: (state: Draft<CommonState>, action: PayloadAction<string>) => {
      state.language = action.payload
    },

    setThemeName: (state: Draft<CommonState>, action: PayloadAction<Branding>) => {
      state.theme = action.payload
    },

    setMaintenance: (state: Draft<CommonState>) => {
      state.maintenance = true
    },

    clearMaintenance: (state: Draft<CommonState>) => {
      state.maintenance = false
    }
  }
})

export const { setLanguage, setThemeName, setMaintenance, clearMaintenance } = commonSlice.actions
export default commonSlice.reducer
