import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButtonAlternate } from '../../../components/buttons'
import Spinner from '../common/Spinner'
import { Popup, PopupBackground, PopupCloseButton, PopupHeader } from '../common/popupElements'
import { CloseIcon } from '../common/icons'
import { Input } from '../common/inputElements'
import { FormatAmount } from '../../../common/formatters'
import { ForwardArrowIcon } from '../../../components/ButtonIcon.component/IconSvg'
import { IconSize, IconColor } from '../../../common/const'

export interface CustomAmountPopupProps {
  isOpen: boolean
  payButtonLabel?: string
  customAmount: string | number
  setCustomAmount: (value: string) => void
  minAmount: number
  maxAmount: number
  isLoading: boolean
  onPay: () => void
  onCancel: () => void
}

const CustomAmountPopup = ({
  isOpen,
  payButtonLabel,
  customAmount,
  setCustomAmount,
  minAmount,
  maxAmount,
  isLoading,
  onPay,
  onCancel
}: CustomAmountPopupProps) => {
  const { t } = useTranslation()

  const customAmountNum =
    typeof customAmount === 'string' ? parseFloat(customAmount.replace(',', '.')) : customAmount
  const isValid =
    !isNaN(customAmountNum) && customAmountNum >= minAmount && customAmountNum <= maxAmount

  return (
    <PopupBackground closed={!isOpen}>
      <Popup align="left" closed={!isOpen} smallPaddings>
        <PopupHeader>
          {t('ontimePayAnotherAmount.label')}
          <PopupCloseButton onClick={onCancel} title={t('close.label')!}>
            <CloseIcon alt={t('close.label')!} />
          </PopupCloseButton>
        </PopupHeader>
        <p>
          {t('ontimePayAnotherAmount.message1')}
          <b>
            <i>
              <FormatAmount>{minAmount}</FormatAmount>
            </i>
          </b>
          {t('ontimePayAnotherAmount.message2')}
          <b>
            <i>
              <FormatAmount>{maxAmount}</FormatAmount>
            </i>
          </b>
          {t('ontimePayAnotherAmount.message3')}
        </p>
        <Input valid={isValid}>
          <label htmlFor="customAmountInput">{t('ontimeAmount.label')}</label>
          <input
            id="customAmountInput"
            type="number"
            min={minAmount}
            max={maxAmount}
            step="1"
            value={customAmount !== null ? customAmount : ''}
            onChange={(e) => setCustomAmount(e.target.value)}
          />
        </Input>
        <PrimaryButtonAlternate onClick={onPay} disabled={isLoading || !isValid}>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {t(payButtonLabel || 'ontimePayInvoiceButton.label')}
              <ForwardArrowIcon color={IconColor.SECONDARY} size={IconSize.SMALL} />
            </>
          )}
        </PrimaryButtonAlternate>
      </Popup>
    </PopupBackground>
  )
}

export default CustomAmountPopup
