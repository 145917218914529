import * as React from 'react'
import { Context, createContext, useContext } from 'react'
import { CurrencySeparator } from './const'
import { formatAmount, formatDate } from './helpers'

interface FormatterContextValue {
  dateFormat?: string | null
  thousandSeparator?: CurrencySeparator
  decimalSeparator?: CurrencySeparator
}

const defaultFormatterContext: FormatterContextValue = {
  dateFormat: null,
  thousandSeparator: CurrencySeparator.NONE,
  decimalSeparator: CurrencySeparator.COMMA
}

export const FormatterContext: Context<FormatterContextValue> =
  createContext(defaultFormatterContext)

export const FormatAmount = ({ children }: { children: number }) => {
  const formatterContext = useContext(FormatterContext)
  return (
    <>
      {formatAmount(
        children,
        formatterContext.thousandSeparator || defaultFormatterContext.thousandSeparator!,
        formatterContext.decimalSeparator || defaultFormatterContext.decimalSeparator!
      )}
    </>
  )
}

export const FormatDate = ({ children }: { children: string }) => {
  const formatterContext = useContext(FormatterContext)
  return (
    <>{formatDate(children, formatterContext.dateFormat || defaultFormatterContext.dateFormat!)}</>
  )
}
