import { ActionReducerMapBuilder, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { InvoicerPaymentMethod, PayStatus } from '../const'
import { authCompleted } from './auth'

interface PayState {
  status: PayStatus | null
  data: { url?: string; paymentProvider: InvoicerPaymentMethod; token: string } | null
}

const INITIAL_STATE: PayState = {
  status: PayStatus.LOADING,
  data: null
}

const paySlice = createSlice({
  name: 'pay',
  initialState: INITIAL_STATE,
  reducers: {
    paySetStatus: (state: Draft<PayState>, action: PayloadAction<PayStatus | null>) => {
      state.status = action.payload
    },

    paySetData: (
      state: Draft<PayState>,
      action: PayloadAction<{
        status: PayStatus
        data: { url?: string; paymentProvider: InvoicerPaymentMethod; token: string }
      }>
    ) => {
      state.status = action.payload.status
      state.data = action.payload.data
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<PayState>) => {
    //This is to prevent bounce back to invoice on path /pay -> /auth -> / -> pay
    builder.addCase(authCompleted, (state) => {
      state.status = PayStatus.LOADING
    })
  }
})

export const { paySetStatus, paySetData } = paySlice.actions
export default paySlice.reducer
