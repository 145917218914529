import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButtonAlternate, SecondaryButton } from '../../../components/buttons'
import Spinner from '../common/Spinner'
import { Popup, PopupBackground, PopupMessageBody, PopupMessage } from '../common/popupElements'
import { BigInfoIcon } from '../common/icons'
import { ForwardArrowIcon } from '../../../components/ButtonIcon.component/IconSvg'
import { IconSize, IconColor } from '../../../common/const'

interface MinimumAmountConfirmationPopupProps {
  isOpen: boolean
  isLoading: boolean
  onPay: () => void
  onCancel: () => void
}

const MinimumAmountConfirmationPopup = ({
  isOpen,
  isLoading,
  onCancel,
  onPay
}: MinimumAmountConfirmationPopupProps) => {
  const { t } = useTranslation()
  const minimumAmountPopUpText = t('ontimePayMinimumAmountPopUp.text')

  return (
    <PopupBackground closed={!isOpen}>
      <Popup align="center" closed={!isOpen}>
        <BigInfoIcon alt={t('paymentSucceeded.label')!} />
        <PopupMessage>{t('ontimePayMinimumAmountPopUp.label')}</PopupMessage>
        <PopupMessageBody
          align="center"
          dangerouslySetInnerHTML={{
            __html: minimumAmountPopUpText
          }}></PopupMessageBody>
        <PrimaryButtonAlternate onClick={onPay} disabled={isLoading}>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {t('ontimePayMinimumAmountButton.label')}
              <ForwardArrowIcon color={IconColor.SECONDARY} size={IconSize.SMALL} />
            </>
          )}
        </PrimaryButtonAlternate>
        <SecondaryButton onClick={onCancel}>{t('back.label')}</SecondaryButton>
      </Popup>
    </PopupBackground>
  )
}

export default MinimumAmountConfirmationPopup
