import * as React from 'react'
import { ChangeEvent, PropsWithChildren } from 'react'
import styled from 'styled-components/macro'

interface SelectProps extends PropsWithChildren {
  label?: string
  value?: string | number
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
}

const Wrapper = styled.div`
  position: relative;
`

const Label = styled.label`
  position: absolute;
  top: 8px;
  left: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${(props) => props.theme.inputForeground};
  pointer-events: none;
  z-index: 1;

  @media (max-width: 374px) {
    & {
      left: 5px;
    }
  }
`

const SelectElement = styled.select`
  background: url(${(props) => props.theme.selectIcon}) top 24px right 8px no-repeat
    ${(props) => props.theme.inputBackground};
  color: ${(props) => props.theme.inputForeground};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.button};
  box-shadow: ${(props) => props.theme.selectBoxShadow};
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  min-width: 80px;
  width: 100%;
  font-family: inherit;
  cursor: inherit;
  font-size: 16px;
  line-height: 24px;
  padding: 24px 32px 8px 20px;

  @media (max-width: 374px) {
    & {
      padding: 24px 17px 8px 5px;
      background-position: top 24px right 5px;
      background-size: 10px 10px;
    }
  }
`

const Select = ({ label, value, onChange, children }: SelectProps) => (
  <Wrapper>
    <Label>{label}</Label>
    <SelectElement title={label} value={value} onChange={onChange}>
      {children}
    </SelectElement>
  </Wrapper>
)

export default Select
