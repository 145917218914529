import React from 'react'
import { FORWARD_ARROW_SVG, BACKWARD_ARROW_SVG } from './buttonIconSvgs'
import { IconSizeProps } from '../../common/const'
import ButtonIcon from './ButtonIconComponent'

interface IconSvgProps {
  color: string
  size: IconSizeProps
}

export const ForwardArrowIcon: React.FC<IconSvgProps> = ({ color, size }) => {
  return <ButtonIcon svgSource={FORWARD_ARROW_SVG} color={color} size={size} />
}

export const BackwardArrowIcon: React.FC<IconSvgProps> = ({ color, size }) => {
  return <ButtonIcon svgSource={BACKWARD_ARROW_SVG} color={color} size={size} />
}
