import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Invoice from '../../../common/types/Invoice'
import { Detail, Divider, Frame, Header, Label, ToPay, Total, Value } from './InvoiceDetails.style'

const InvoiceDetails = ({ invoice }: { invoice: Invoice }) => {
  const { t } = useTranslation()

  const total = invoice.invoiceDetails.total.toFixed(2).replace('.', ',')
  return (
    <Frame>
      <Header>{t('inkassoPayInfo.label')}</Header>
      <Divider />
      <Detail>
        <Label>{t('inkassoRecipient.label')} </Label>
        <Value>{invoice.invoicerDetails.companyName}</Value>
      </Detail>
      <Detail>
        <Label>{t('inkassoPlusgiro.label')}</Label>
        <Value>{invoice.invoiceDetails.plusgirot}</Value>
      </Detail>
      <Detail>
        <Label>{t('inkassoBankgiro.label')}</Label>
        <Value>{invoice.invoiceDetails.bankgirot}</Value>
      </Detail>
      <Detail>
        <Label>{t('inkassoOCR.label')}</Label>
        <Value>{invoice.invoiceDetails.reference}</Value>
      </Detail>
      <Total>{total} kr</Total>
      <ToPay>{t('inkassoToPay.label')}</ToPay>
    </Frame>
  )
}

export default InvoiceDetails
