import * as React from 'react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../../common/store'
import { LogoutLink, Wrapper } from './Header.style'
import { getThemeFromState } from '../../../common/themes/theme'
import { useDispatch } from 'react-redux'
import { clearAuth } from '../../../common/state/auth'
import { Redirect } from 'react-router-dom'

type LogoHeaderConnectedProps = ConnectedProps<typeof connector>
interface LogoHeaderProps extends LogoHeaderConnectedProps {
  authenticationRequired?: boolean
}
const LogoHeader = ({ jwtToken, sessionId, authenticationRequired }: LogoHeaderProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = getThemeFromState()
  const [logOut, setLogOut] = useState<boolean>(false)

  const LogoutClicked = useCallback(() => {
    if (authenticationRequired) {
      setLogOut(true)
      return
    }
    dispatch(clearAuth())
  }, [authenticationRequired, dispatch])

  if (logOut) {
    return <Redirect to={'/logout'} />
  }

  return (
    <Wrapper>
      <img src={theme.smallLogo.image} alt={theme.smallLogo.alt} height={theme.smallLogo.height} />
      {jwtToken && sessionId && (
        <LogoutLink onClick={LogoutClicked}>{t('ontimeLogoutButton.label')}</LogoutLink>
      )}
    </Wrapper>
  )
}

const connector = connect((state: RootState) => ({
  jwtToken: state.auth.jwtToken,
  sessionId: state.auth.sessionId
}))
export default connector(LogoHeader)
