import styled, { DefaultTheme } from 'styled-components/macro'

interface StyledSVGPathProps {
  theme: DefaultTheme
  color: string
}

export const StyledSVGPath = styled.path`
  fill: ${(props: StyledSVGPathProps) =>
    props.color === 'primary'
      ? props.theme.buttonIconPrimaryColor
      : props.theme.buttonIconSecondaryColor};
`

export const StyledSVGContainer = styled.div`
  background: 'red';
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 0 5px;
`
