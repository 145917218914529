import * as React from 'react'
import { EyeIcon, LockedIcon } from '../common/icons'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '../../../components/buttons'

interface InvoiceMoreDetailsButtonProps {
  isAuthenticated: boolean
  isDisabled: boolean
  onShowMoreDetails: () => void
}
const InvoiceMoreDetailsButton = ({
  isAuthenticated,
  isDisabled,
  onShowMoreDetails
}: InvoiceMoreDetailsButtonProps) => {
  const { t } = useTranslation()

  return (
    <SecondaryButton onClick={onShowMoreDetails} disabled={isDisabled}>
      {isAuthenticated ? (
        <>
          {t('moreDetailsAuthenticated.label')}
          <EyeIcon />
        </>
      ) : (
        <>
          {t('moreDetailsNotAuthenticated.label')}
          <LockedIcon />
        </>
      )}
    </SecondaryButton>
  )
}

export default InvoiceMoreDetailsButton
