import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import Spinner from './components/Spinner/Spinner.component'
import { payInkassoInvoice } from '../common/backend'
import { paySetData, paySetStatus } from '../common/state/pay'
import { setMaintenance } from '../common/state/common'
import { InvoicerPaymentMethod, PayStatus } from '../common/const'
import { RootState } from '../common/store'
import { AxiosResponse } from 'axios'

type PayPageConnectedProps = ConnectedProps<typeof connector>

class PayPage extends React.Component<
  PayPageConnectedProps & WithTranslation & RouteComponentProps<{ invoiceId: string }>
> {
  componentDidMount() {
    const invoiceId = this.props.match.params.invoiceId
    if (!this.props.jwtToken || !this.props.sessionId) {
      this.props.paySetStatus(PayStatus.AUTH_REQUIRED)
      return
    }

    payInkassoInvoice(invoiceId, this.props.jwtToken, this.props.sessionId)
      .then((response) => this.handlePayResponse(response))
      .catch((err) => this.handleError(err))
  }

  handlePayResponse(
    response: AxiosResponse<
      | { url?: string; paymentProvider: InvoicerPaymentMethod; token: string }
      | { status: 'expired' }
    >
  ) {
    if ('paymentProvider' in response.data) {
      this.props.paySetData({ status: PayStatus.OK, data: response.data })
    } else if ('status' in response.data && response.data.status === 'expired') {
      this.props.paySetStatus(PayStatus.EXPIRED)
    }
  }

  handleError(err: any) {
    if (err.response.status === 403 || err.response.status === 401) {
      this.props.paySetStatus(PayStatus.AUTH_REQUIRED)
    } else if (err.response.status === 503) {
      this.props.setMaintenance()
    } else {
      console.log(err)
      this.props.paySetStatus(PayStatus.ERROR)
    }
  }

  render() {
    if (this.props.maintenance) {
      return <Redirect to={`/maintenance`} />
    }

    const { t } = this.props
    const invoiceId = this.props.match.params.invoiceId
    switch (this.props.status) {
      case PayStatus.LOADING:
        return <Spinner />
      case PayStatus.ERROR:
        return t('technicalProblem.label')
      case PayStatus.EXPIRED:
        return t('invoiceExpired.label')
      case PayStatus.AUTH_REQUIRED:
        return <Redirect to={`/${invoiceId}/auth`} />
      case PayStatus.OK:
        if (this.props.data) {
          if (this.props.data.paymentProvider === 'g3') {
            console.log('redirecting to ' + this.props.data.url)
            window.open(this.props.data.url, '_self')
            return <Spinner />
          } else {
            return t('paymentUnknownProvider.label')
          }
        } else {
          return t('missingData.label')
        }
      default:
        return t('unknownStatus.label')
    }
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    status: state.pay.status,
    data: state.pay.data,
    maintenance: state.common.maintenance,
    jwtToken: state.auth.jwtToken,
    sessionId: state.auth.sessionId
  }
}

const connector = connect(mapStateToProps, {
  paySetStatus,
  paySetData,
  setMaintenance
})
export default connector(withTranslation()(PayPage))
