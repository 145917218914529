import * as React from 'react'
import { CloseIcon } from '../common/icons'

import IconButton from '@mui/material/IconButton'

interface InvoiceDetailsCopyButtonProps {
  handleClick: (event: React.SyntheticEvent | Event, reason?: string) => void
}

const SnackBarCloseButton = ({ handleClick }: InvoiceDetailsCopyButtonProps) => {
  return (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClick}>
      <CloseIcon />
    </IconButton>
  )
}

export default SnackBarCloseButton
