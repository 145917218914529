import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  background: ${(props) => props.theme.pdfBackground};
`

export const Header = styled.div`
  display: flex;

  & h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    margin: 10px 0;
    flex-grow: 1;
  }

  & button {
    background: none;
    height: 19px;
    width: 19px;
    border: 0;
    padding: 0;
    cursor: pointer;
  }
`

export const DownloadButton = styled.button.attrs({ type: 'button' })`
  margin: 10px 15px;
`

export const CloseButton = styled.button.attrs({ type: 'button' })`
  margin: 15px;
`

export const Body = styled.div`
  overflow-y: scroll;
`
