import styled from 'styled-components/macro'

export const MainFrame = styled.div`
  padding: 0;
  background-color: ${(props) => props.theme.background};
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`

export const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
`

export const Logo = styled.img`
  height: 50px;
  margin-top: 60px;
  margin-left: 60px;
  margin-bottom: 42px;

  @media not all and (min-width: 960px) {
    margin-left: 0;
    margin-top: 100px;
    margin-bottom: 30px;
  }
`

export const Frame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  @media not all and (min-width: 960px) {
    margin-top: 40px;
  }
`

export const GridContentsWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const InfoWrapper = styled.div`
  width: 335px;
`

export const Header = styled.div`
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.text};
`

export const DueDate = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.text};
`

export const PdfWrapper = styled.div`
  max-width: 360px;
`

export const DividerWrapper = styled.div`
  width: 100%;
  flex-shrink: 0;
`

export const Divider = styled.div`
  background-color: ${(props) => props.theme.button};
  height: 3px;
  width: 100%;
  margin: 200px 0 60px;

  @media not all and (min-width: 960px) {
    margin-top: 0;
  }
`

export const OnlyDesktop = styled.div`
  @media not all and (min-width: 960px) {
    width: 100%;
    visibility: hidden;
    height: 0;
  }
`

export const OnlyMobile = styled.div`
  @media (min-width: 960px) {
    visibility: hidden;
    height: 0;
  }
`
