import './common/index.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import './common/polyfills.js'
import * as React from 'react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { pdfjs } from 'react-pdf'
import { App } from './App'

// Use external pdf worker for pdf viewers
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const root = createRoot(document.getElementById('root')!)
root.render(
  <StrictMode>
    <App />
  </StrictMode>
)

if (process.env.REACT_APP_ENV !== 'development' && process.env.REACT_APP_ENV !== 'test') {
  console.log = () => {}
}
