import styled from 'styled-components/macro'

export const HeaderExpander = styled.button.attrs({ type: 'button' })`
  border: none;
  background: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  font-family: Asap;
  padding: 14px;

  & div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;
    margin: 0 14px;
  }

  & div h3 {
    color: #4b6b22;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 5px;
  }

  & div p {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
`

export const Details = styled.div<{ open: boolean }>`
  overflow: hidden;
  transition: max-height 0.5s;
  max-height: ${(props) => (props.open ? '200px' : '0')};
`

export const Summary = styled.div`
  background-color: #e7fcd0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 12px 0;

  & label,
  & span {
    margin-bottom: 0;
  }
`
