import * as React from 'react'
import { Wrapper } from './Spinner.style'

const Spinner = () => {
  return (
    <Wrapper>
      <img src="/img/inkasso_spinner.gif" alt="spinner" />
    </Wrapper>
  )
}

export default Spinner
