import styled from 'styled-components/macro'

export const Frame = styled.div`
  margin-bottom: 20px;
  background-color: ${(props) => props.theme.panel};
  border-radius: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  height: 295px;
`

export const Header = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.text};
  padding-left: 20px;
  padding-top: 12px;
`

export const Divider = styled.hr`
  background-color: ${(props) => props.theme.divider};
  height: 1px;
  width: 295px;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.divider};
`

export const Detail = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`

export const Label = styled.div`
  margin-left: 20px;
  margin-bottom: 12px;
  margin-right: auto;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.text};
`

export const Value = styled.div`
  margin-right: 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: ${(props) => props.theme.text};
`

export const Total = styled.div`
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.19;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => props.theme.heading};
  height: 25px;
`

export const ToPay = styled.div`
  height: 10px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 5;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => props.theme.text};
`
