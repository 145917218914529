import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeProvider } from 'styled-components/macro'
import { clearMaintenance } from '../../common/state/common'
import {
  LoggedOutLayout,
  LoggedOutMain,
  LoggedOutMessage
} from '../components/LoggedOutElements/loggedOutElements.style'
import { LoggedOutLogo } from '../components/LoggedOutElements/loggedOutElements.component'
import { BigWarningIcon } from '../components/common/icons'
import { getThemeFromState } from '../../common/themes/theme'
import { useDispatch } from 'react-redux'

const MaintenancePage = () => {
  const { t } = useTranslation()
  const theme = getThemeFromState()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(clearMaintenance())
  }, [dispatch])

  return (
    <ThemeProvider theme={theme.auth}>
      <LoggedOutLayout>
        <LoggedOutMain>
          <LoggedOutLogo {...theme.logo} />
        </LoggedOutMain>
        <LoggedOutMessage>
          <BigWarningIcon alt={t('error.label')!} />
          <h2>Järjestelmän ylläpito käynnissä, yritä uudelleen myöhemmin</h2>
          <h3>Systemunderhåll på gång, försök pånytt senare</h3>
          <h3>System maintenance ongoing, please try again later</h3>
        </LoggedOutMessage>
      </LoggedOutLayout>
    </ThemeProvider>
  )
}

export default MaintenancePage
