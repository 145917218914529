import * as React from 'react'
import { LogInButtonWrapper } from '../LoggedOutElements/loggedOutElements.style'
import { PrimaryButtonAlternate } from '../../../components/buttons'
import Spinner from './Spinner'
import { useTranslation } from 'react-i18next'

export interface LoginButtonProps {
  handleOnStartAuth?: () => void
  authPending: boolean
}

const LoginButton = ({ handleOnStartAuth, authPending }: LoginButtonProps) => {
  const { t } = useTranslation()

  return (
    <LogInButtonWrapper>
      <PrimaryButtonAlternate
        onClick={handleOnStartAuth || (() => {})}
        disabled={authPending || !handleOnStartAuth}>
        {authPending ? (
          <Spinner />
        ) : (
          <>
            {t('ontimeSignicatLogin.label')}
            &nbsp;
            <img src="/img/signicat.png" alt="SigniCat" width="57" height="24" />
          </>
        )}
      </PrimaryButtonAlternate>
    </LogInButtonWrapper>
  )
}

export default LoginButton
