import * as React from 'react'
import { useTranslation } from 'react-i18next'
import fileDownload from 'js-file-download'
import { PdfStatus } from '../../../common/const'
import { PropsWithChildren } from 'react'
import { CloseIcon, DownloadIcon } from '../common/icons'
import { Body, CloseButton, DownloadButton, Header, Wrapper } from './InvoicePdf.style'

interface InvoicePdfProps {
  title: string
  pdfStatus: PdfStatus
  pdfContent: string | null
  filename: string | null
  onClose: () => void
}

const InvoicePdf = ({
  children,
  title,
  pdfStatus,
  pdfContent,
  filename,
  onClose
}: PropsWithChildren<InvoicePdfProps>) => {
  const { t } = useTranslation()

  const download = () => {
    if (!pdfContent || !filename) return
    const binaryString = window.atob(pdfContent)
    const binaryLen = binaryString.length
    const bytes = new Uint8Array(binaryLen)
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    fileDownload(bytes, filename)
  }

  return (
    <Wrapper>
      <Header>
        {pdfStatus === PdfStatus.OK && (
          <DownloadButton onClick={download} title={t('download.label')!}>
            <DownloadIcon alt={t('download.label')!} />
          </DownloadButton>
        )}
        <h4>{t(title)}</h4>
        <CloseButton onClick={onClose} title={t('close.label')!}>
          <CloseIcon alt={t('close.label')!} />
        </CloseButton>
      </Header>
      <Body>{children}</Body>
    </Wrapper>
  )
}

export default InvoicePdf
