import * as React from 'react'
import styled from 'styled-components/macro'
import Button from '@mui/material/Button'
import { PropsWithChildren } from 'react'

const PrimaryButtonWrapped = styled(Button)`
  && {
    box-shadow: none;
    text-transform: none;
    padding: 6px 12px;
    border: 2px solid;
    border-radius: 150px;
    background-color: ${(props) => props.theme.link};
    border-color: ${(props) => props.theme.link};
    font-family: Asap;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    min-height: 50px;
    text-shadow: 0 0 1px ${(props) => props.theme.panel};
    &:hover {
      background-color: ${(props) => props.theme.link};
      border-color: ${(props) => props.theme.link};
    }
    &:active {
      background-color: ${(props) => props.theme.link};
      border-color: ${(props) => props.theme.link};
    }
    &:focus {
      background-color: ${(props) => props.theme.link};
      border-color: ${(props) => props.theme.link};
    }
  }
`

export const PrimaryButton = ({
  onClick,
  disabled,
  children
}: PropsWithChildren<{ onClick?: () => void; disabled?: boolean }>) => {
  return (
    <PrimaryButtonWrapped
      fullWidth
      variant="contained"
      size="small"
      color="secondary"
      disabled={disabled}
      onClick={onClick ? () => onClick() : () => {}}>
      {children}
    </PrimaryButtonWrapped>
  )
}

const PrimaryButtonAlternateWrapped = styled(Button)`
  && {
    box-shadow: none;
    text-transform: none;
    padding: 6px 12px;
    margin-bottom: 10px;
    border: 2px solid;
    border-radius: 150px;
    background-color: ${(props) => props.theme.button};
    border-color: ${(props) => props.theme.button};
    font-family: Asap;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    min-height: 50px;
    text-shadow: 0 0 1px ${(props) => props.theme.panel};
    &:hover {
      background-color: ${(props) => props.theme.button};
      border-color: ${(props) => props.theme.button};
    }
    &:active {
      background-color: ${(props) => props.theme.button};
      border-color: ${(props) => props.theme.button};
    }
    &:focus {
      background-color: ${(props) => props.theme.button};
      border-color: ${(props) => props.theme.button};
    }
  }
`

export const PrimaryButtonAlternate = ({
  onClick,
  disabled,
  children
}: PropsWithChildren<{ onClick?: () => void; disabled?: boolean }>) => {
  return (
    <PrimaryButtonAlternateWrapped
      fullWidth
      variant="contained"
      size="small"
      color="secondary"
      onClick={onClick}
      disabled={disabled}>
      {children}
    </PrimaryButtonAlternateWrapped>
  )
}

const SecondaryButtonWrapped = styled(Button)`
  && {
    box-shadow: none;
    text-transform: none;
    color: ${(props) => props.theme.button};
    padding: 6px 12px;
    border: 2px solid;
    border-radius: 150px;
    background-color: ${(props) => props.theme.background};
    border-color: ${(props) => props.theme.button};
    font-family: Asap;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    min-height: 50px;
    text-shadow: 0 0 1px ${(props) => props.theme.button};
    &:hover {
      background-color: ${(props) => props.theme.background};
      border-color: ${(props) => props.theme.button};
    }
    &:active {
      background-color: ${(props) => props.theme.background};
      border-color: ${(props) => props.theme.button};
    }
    &:focus {
      background-color: ${(props) => props.theme.background};
      border-color: ${(props) => props.theme.button};
    }
    img {
      width: 21px;
      margin-left: 10px;
    }
  }
`

export const SecondaryButton = ({
  onClick,
  disabled,
  children
}: PropsWithChildren<{ onClick?: () => void; disabled?: boolean }>) => {
  return (
    <SecondaryButtonWrapped fullWidth variant="contained" onClick={onClick} disabled={disabled}>
      {children}
    </SecondaryButtonWrapped>
  )
}

const ButtonWithoutBordersWrapped = styled.button`
  && {
    box-shadow: none;
    text-transform: none;
    border: none;
    background: none;
    justify-content: center;
    color: ${(props) => props.theme.button};
    font-family: Asap;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    min-height: 50px;
    text-shadow: 0 0 1px ${(props) => props.theme.button};
    padding: 6px 12px;
  }
`

const ButtonWithoutBordersText = styled.p`
  && {
    font-size: 16px;
    width: fit-content;
    margin: auto;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
`

export const ButtonWithoutBorders = ({
  onClick,
  disabled,
  children
}: PropsWithChildren<{ onClick?: () => void; disabled?: boolean }>) => {
  return (
    <ButtonWithoutBordersWrapped aria-disabled={disabled}>
      <ButtonWithoutBordersText onClick={onClick}>{children}</ButtonWithoutBordersText>
    </ButtonWithoutBordersWrapped>
  )
}

const MarketingButtonWrapped = styled(Button)`
  && {
    box-shadow: none;
    text-transform: none;
    border: 2px solid;
    border-radius: 150px;
    background-color: ${(props) => props.theme.marketingButtonBackgroundColor};
    border-color: ${(props) => props.theme.marketingButtonBorderColor};
    color: ${(props) => props.theme.marketingButtonColor};
    font-family: Asap;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5rem;
    letter-spacing: normal;
    min-height: 50px;
    text-shadow: 0 0 1px ${(props) => props.theme.panel};
    margin: 10px 0;

    &:hover {
      background-color: ${(props) => props.theme.marketingButtonBackgroundColor};
      border-color: ${(props) => props.theme.marketingButtonBorderColor};
    }
    &:active {
      background-color: ${(props) => props.theme.marketingButtonBackgroundColor};
      border-color: ${(props) => props.theme.marketingButtonBorderColor};
    }
    &:focus {
      background-color: ${(props) => props.theme.marketingButtonBackgroundColor};
      border-color: ${(props) => props.theme.marketingButtonBorderColor};
    }
  }
`

const GoBackButtonWrapped = styled(Button)`
  max-width: 200px;
  width: 150px;
  margin: 0 auto 10px;

  @media (max-width: 959px) {
    & {
      max-width: 335px;
      margin-bottom: 10px;
    }
  }
  && {
    box-shadow: none;
    text-transform: none;
    border: 2px solid;
    border-radius: 150px;
    background-color: ${(props) => props.theme.marketingButtonBackgroundColor};
    border-color: ${(props) => props.theme.marketingButtonBorderColor};
    color: ${(props) => props.theme.marketingButtonColor};
    font-family: Asap;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5rem;
    letter-spacing: normal;
    min-height: 50px;
    text-shadow: 0 0 1px ${(props) => props.theme.panel};
    margin: 10px 0;

    &:hover {
      background-color: ${(props) => props.theme.marketingButtonBackgroundColor};
      border-color: ${(props) => props.theme.marketingButtonBorderColor};
    }
    &:active {
      background-color: ${(props) => props.theme.marketingButtonBackgroundColor};
      border-color: ${(props) => props.theme.marketingButtonBorderColor};
    }
    &:focus {
      background-color: ${(props) => props.theme.marketingButtonBackgroundColor};
      border-color: ${(props) => props.theme.marketingButtonBorderColor};
    }
  }
`

export const MarketingButton = ({
  onClick,
  children
}: PropsWithChildren<{ onClick?: () => void }>) => {
  return (
    <MarketingButtonWrapped
      fullWidth
      variant="contained"
      size="small"
      color="secondary"
      onClick={onClick ? () => onClick() : () => {}}>
      {children}
    </MarketingButtonWrapped>
  )
}

export const GoBackButtonAuth = ({
  onClick,
  children
}: PropsWithChildren<{ onClick?: () => void }>) => {
  return (
    <GoBackButtonWrapped
      variant="contained"
      size="small"
      color="secondary"
      onClick={onClick ? () => onClick() : () => {}}>
      {children}
    </GoBackButtonWrapped>
  )
}
