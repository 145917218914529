import * as React from 'react'
import { Logo, Wrapper1, Wrapper2 } from './SveaAuthLogo.style'

const SveaAuthLogo = ({ type }: { type: 'mobile' | 'desktop' }) => {
  return (
    <Wrapper1 type={type}>
      <Wrapper2 type={type}>
        <Logo src="/img/Svea_logo.png" alt="Svea" />
      </Wrapper2>
    </Wrapper1>
  )
}

export default SveaAuthLogo
