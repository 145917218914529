import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Select from '../common/Select'
import { BirthdaySelectWrapper } from './LoginBirthdaySelector.style'

export interface LoginBirthdaySelectorProps {
  day: number
  month: number
  year: number
  handleOnSetDay: (day: number) => void
  handleOnSetMonth: (month: number) => void
  handleOnSetYear: (year: number) => void
}

const getNumberOfDays = (y: number, m: number) => new Date(y, m, 0).getDate()
const range = (n: number) => Array.from({ length: n }, (x, i) => i)

const getMonthName = (month: number, language: string) => {
  // We use Serbian Latin for Serbian localization; it appears that the default for Serbian is Serbian Cyrillic.
  // Hopefully that sr-latn is universally supported too...
  if (language === 'sr') {
    language = 'sr-latn'
  }
  const date = new Date(2023, month - 1, 1)
  return date.toLocaleString(language, { month: 'long' })
}

const LoginBirthdaySelector = ({
  day,
  month,
  year,
  handleOnSetDay,
  handleOnSetMonth,
  handleOnSetYear
}: LoginBirthdaySelectorProps) => {
  const { t, i18n } = useTranslation()
  const thisYear = new Date().getFullYear()

  return (
    <BirthdaySelectWrapper>
      <Select
        label={t('day.label')!}
        value={day}
        onChange={(e) => handleOnSetDay(parseInt(e.target.value))}>
        {range(getNumberOfDays(year, month))
          .map((d) => d + 1)
          .map((d) => (
            <option value={d} key={d}>
              {d}
            </option>
          ))}
      </Select>
      <Select
        label={t('month.label')!}
        value={month}
        onChange={(e) => {
          handleOnSetMonth(parseInt(e.target.value))
          const daysInNewMonth = getNumberOfDays(year, parseInt(e.target.value))
          if (daysInNewMonth < day) {
            handleOnSetDay(daysInNewMonth)
          }
        }}>
        {range(12)
          .map((m) => m + 1)
          .map((m) => (
            <option value={m} key={m}>
              {getMonthName(m, i18n.language)}
            </option>
          ))}
      </Select>
      <Select
        label={t('year.label')!}
        value={year}
        onChange={(e) => handleOnSetYear(parseInt(e.target.value))}>
        {/* Suggest years from -150 to -13 years from now */}
        {range(137)
          .map((y) => thisYear - 149 + y)
          .map((y) => (
            <option value={y} key={y}>
              {y}
            </option>
          ))}
      </Select>
    </BirthdaySelectWrapper>
  )
}

export default LoginBirthdaySelector
