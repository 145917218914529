import * as React from 'react'
import { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { DefaultTheme, ThemeProvider } from 'styled-components/macro'
import Favicon from 'react-favicon'
import OntimeInvoicePage from './ontime/routes/Invoice.page'
import OntimeLogoutPage from './ontime/routes/Logout.page'
import OntimeAuthPage from './ontime/routes/Auth.page'
import OntimeCallbackPage from './ontime/routes/Callback.page'
import OntimeNotFoundPage from './ontime/routes/NotFound.page'
import OntimeMaintenancePage from './ontime/routes/Maintenance.page'
import { GlobalFont } from './common/styles'
import {
  getThemeFromState,
  getThemeName,
  getThemeNameFromState,
  getThemePrimaryLanguage
} from './common/themes/theme'
import { setThemeName, setLanguage } from './common/state/common'
import { setPreviousInvoiceId } from './common/state/invoice'
import store from './common/store'
import './common/i18n'
import i18n from './common/i18n'

const OntimeApp = () => {
  const [theme, setTheme] = React.useState<DefaultTheme>(getThemeFromState())
  const [themeFetched, setThemeFetched] = React.useState<boolean>(false)
  const dispatch = useDispatch()

  React.useEffect(() => {
    const setThemeAndLang = async () => {
      const currentInvoiceId = getInvoiceId()
      const previousInvoiceId = store.getState()?.invoice?.previousInvoiceId
      let themeName
      if (!previousInvoiceId || currentInvoiceId !== previousInvoiceId) {
        themeName = await getThemeName(currentInvoiceId)
        dispatch(setPreviousInvoiceId(currentInvoiceId))
      } else {
        themeName = getThemeNameFromState()
      }
      setThemeFetched(true)

      dispatch(setThemeName(themeName))

      const theme = getThemeFromState()
      const stateLang = store.getState()?.common?.language
      if (!stateLang || !theme.languages.includes(stateLang)) {
        dispatch(setLanguage(getThemePrimaryLanguage()))
        i18n.changeLanguage(getThemePrimaryLanguage())
      }
      setTheme(theme)
    }

    setThemeAndLang()
  }, [dispatch])

  if (!themeFetched) {
    console.warn('Theme has not been fetched yet or has failed, will not render page.')
    return <></>
  }

  if (process.env.REACT_APP_MAINTENANCE === 'true') {
    return (
      <ThemeProvider theme={theme}>
        <GlobalFont />
        <div className="App">
          <OntimeMaintenancePage />
        </div>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <GlobalFont />
        <div className="App">
          <Favicon url={theme.favicon} />
          <Suspense fallback="">
            <Switch>
              <Route path="/logout" exact component={OntimeLogoutPage} />
              <Route path="/maintenance" exact component={OntimeMaintenancePage} />
              <Route path="/callback" exact component={OntimeCallbackPage} />
              <Route path="/:invoiceId" exact component={OntimeInvoicePage} />
              <Route path="/:invoiceId/payAction" component={OntimeInvoicePage} />
              <Route path="/:invoiceId/auth" exact component={OntimeAuthPage} />
              <Route path="/:invoiceId/:sessionId?" exact component={OntimeInvoicePage} />
              <Route path="*" component={OntimeNotFoundPage} />
            </Switch>
          </Suspense>
        </div>
      </Router>
    </ThemeProvider>
  )
}

const getInvoiceId = () => {
  const urlInvoiceId = window.location.pathname.split('/')[1]
  if (urlInvoiceId && urlInvoiceId !== 'callback') {
    return urlInvoiceId
  }
  return store.getState()?.invoice?.invoiceId
}

export default OntimeApp
