import styled from 'styled-components/macro'

export const LoggedOutLayout = styled.div`
  background-image: ${(props) => props.theme.logout.backgroundImage};
  background-position: center;
  background-size: cover;
  background-color: ${(props) => props.theme.background};
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 959px) {
    & {
      overflow-x: hidden;
      min-width: 320px;
    }
  }
`

export const LoggedOutMain = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  & h2 {
    font-style: italic;
    font-size: 32px;
    line-height: 40px;
    color: ${(props) => props.theme.heading};
    text-align: center;
  }
`

export const LoggedOutLogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`

export const LogInButtonWrapper = styled.div`
  width: 80%;
  margin: 0 auto 10px;

  @media (max-width: 959px) {
    & {
      max-width: 335px;
      margin-bottom: 10px;
    }
  }

  h2 {
    color: ${(props) => props.theme.text};
    font-style: ${(props) => props.theme.logout.h2FontStyle};
    font-size: ${(props) => props.theme.logout.h2FontSize};
    font-weight: ${(props) => props.theme.logout.h2FontWeight};
    text-transform: ${(props) => props.theme.logout.h2TextTranform};
  }
`

export const LoggedOutMessage = styled.div`
  background: ${(props) => props.theme.inputBackground};
  box-shadow: 0 24px 24px rgba(0, 0, 0, 0.3);
  border-radius: 12px 12px 0 0;
  color: ${(props) => props.theme.heading};
  width: 100%;
  text-align: center;
  padding-top: 40px;

  & h2 {
    font-style: italic;
    font-size: 32px;
    line-height: 40px;
  }
`
