import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Wrapper, Heading, Image } from './LinkedExpired.styles'

const LinkExpired = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Heading>{t('invoiceExpired.label')}</Heading>
      <Image src="/img/link_expired.svg" alt={t('invoiceExpired.label')!} />
    </Wrapper>
  )
}

export default LinkExpired
