import styled from 'styled-components/macro'

export const InvoiceMoreDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
  margin: 30px auto 19px;

  & > div {
    flex-grow: 1;
    text-align: center;
  }

  & > div > h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: ${(props) => props.theme.text};
    margin: 0;
  }

  & > div > span {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
  }
`
