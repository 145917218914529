import styled from 'styled-components/macro'

export const Wrapper = styled.header`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
  background: ${(props) => props.theme.headerBackground};
  & img {
    margin: 16px;
  }
`

export const LogoutLink = styled.p`
  color: ${(props) => props.theme.logoutLinkColor};
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-decoration: none;
  padding-right: 20px;
  background: url('/img/logout.png') right center/16px 16px no-repeat;
  margin: 16px;
`
