import * as React from 'react'
import { PropsWithChildren } from 'react'
import styled from 'styled-components/macro'

export const PopupBackground = styled.div<{ closed?: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s;
  opacity: ${(props) => (props.closed ? 0 : 1)};
  pointer-events: ${(props) => (props.closed ? 'none' : 'all')};
`

const PopupWrapper = styled.div<{ closed?: boolean; align: 'center' | 'left' }>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin: ${(props) => (props.closed ? '0 8px -100vh' : '0 8px')};
  transition: margin-bottom 1s;
`

const PopupElement = styled.div<{
  closed?: boolean
  align: 'center' | 'left'
  smallPaddings?: boolean
}>`
  background: ${(props) => props.theme.panel};
  box-shadow: 0 24px 24px rgba(0, 0, 0, 0.3);
  border-radius: 12px 12px 0 0;
  color: ${(props) => props.theme.text};
  font-size: 14px;
  line-height: 26px;
  text-align: ${(props) => props.align};
  margin: auto;
  padding: ${(props) => (props.smallPaddings ? '1px 23px 50px' : '40px 10px 10px 10px')};
  max-width: 600px;
`

export const Popup = ({
  children,
  closed,
  align,
  smallPaddings
}: PropsWithChildren<{ closed?: boolean; align: 'center' | 'left'; smallPaddings?: boolean }>) => (
  <PopupWrapper align={align} closed={closed}>
    <PopupElement align={align} closed={closed} smallPaddings={smallPaddings}>
      {children}
    </PopupElement>
  </PopupWrapper>
)

export const PopupHeader = styled.h2`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 23px 0 0 0;
  font-style: italic;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.heading};
`

export const PopupMessage = styled.h2`
  font-style: italic;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: ${(props) => props.theme.heading};
`

export const PopupMessageBody = styled.div<{ align: 'center' | 'left' | 'right' | 'justify' }>`
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: ${(props) => props.align};
  margin: 0 auto;
  padding: 0 10px 40px 10px;
  width: 276px;
`

export const PopupCloseButton = styled.button.attrs({ type: 'button' })`
  border: none;
  background: none;
  outline: none;
  padding: 0;
  width: 16px;
  height: 16px;
`
