import * as React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { clearAuth } from '../common/state/auth'
import { SecondaryButton } from '../components/buttons'

const Main = styled.div`
  padding: 0;
  background-color: ${(props) => props.theme.background};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Child = styled.div`
  padding-top: 20px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
`

const Header = styled.div`
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  text-align: left;
  color: ${(props) => props.theme.text};
  width: 100%;
`

type LogoutPageProps = ConnectedProps<typeof connector> & WithTranslation

class NotAuthorizedPage extends React.Component<LogoutPageProps> {
  logout() {
    this.props.clearAuth()
    window.location.href = 'https://svea.com'
  }

  render() {
    const { t } = this.props
    return (
      <Main>
        <Child>
          <Header>{t('notAuthorized.label')}</Header>
        </Child>
        <Child>
          <SecondaryButton onClick={() => this.logout()}>
            {t('inkassoLogoutButton.label')}
          </SecondaryButton>
        </Child>
      </Main>
    )
  }
}

const connector = connect(null, { clearAuth })
export default connector(withTranslation()(NotAuthorizedPage))
