import { getInvoiceMarketing } from '../../../common/backend'

export interface Langs {
  en?: string
  fi?: string
  sv?: string
  sr?: string
}

export interface MarketingBannerData {
  link: string
  banner: Langs
  buttonText: Langs
}

export const getMarketingData = async (invoiceId: string): Promise<MarketingBannerData | null> => {
  const response = await getInvoiceMarketing(invoiceId)
  if (Object.keys(response.data).length !== 0) {
    return response.data
  }
  return null
}
