import * as React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import InvoiceLayout from '../components/InvoiceLayout/InvoiceLayout.component'

const Heading = styled.h2`
  margin: 0 auto 70px;
  font-size: 24px;
  line-height: 40px;
  font-weight: 600;
  font-style: italic;
  text-align: center;
`

const Image = styled.img`
  width: calc(min(400px, 100vw - 30px));
`

const NoInvoicePage = () => {
  const { t } = useTranslation()

  return (
    <InvoiceLayout>
      <Heading>{t('invoiceMissing.label')}</Heading>
      <Image src="/img/try-again.png" alt={t('invoiceMissing.label')!} />
    </InvoiceLayout>
  )
}

export default NoInvoicePage
