import * as React from 'react'
import { Document, Page, PDFPageProxy } from 'react-pdf'
import { WithTranslation, withTranslation } from 'react-i18next'
import { PdfStatus } from '../../../common/const'
import Spinner from '../common/Spinner'
import { Container, Placeholder } from './pdfViewer.style'

interface PdfViewerProps extends WithTranslation {
  pdfStatus: PdfStatus
  pdfContent: string | null
  filename: string | null
}
interface PdfViewerState {
  numPages: number | null
  pageNumber: number
  scale: number
}

class PdfViewer extends React.Component<PdfViewerProps, PdfViewerState> {
  constructor(props: PdfViewerProps) {
    super(props)
    this.state = {
      numPages: null,
      pageNumber: 1,
      scale: 1.0
    }
  }

  onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    this.setState({
      numPages: numPages
    })
  }

  onPageLoad = (page: PDFPageProxy) => {
    const parentDiv = document.querySelector('#pdfDocument')
    const pageScale = parentDiv!.clientWidth / page.originalWidth
    if (this.state.scale !== pageScale) {
      this.setState({ scale: pageScale })
    }
  }

  render() {
    const { t } = this.props
    if (this.props.pdfStatus === PdfStatus.OK) {
      return (
        <Container id="pdfDocument">
          <Document
            file={`data:application/pdf;base64,${this.props.pdfContent}`}
            onLoadSuccess={this.onDocumentLoadSuccess}
            loading={''}>
            {/* Remove magic number, handle resize */}
            {Array.from(new Array(this.state.numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                onLoadSuccess={this.onPageLoad}
                scale={this.state.scale}
                loading={''}
              />
            ))}
          </Document>
        </Container>
      )
    } else if (
      this.props.pdfStatus === PdfStatus.NOT_LOADED ||
      this.props.pdfStatus === PdfStatus.LOADING
    ) {
      return (
        <Placeholder>
          <Spinner />
        </Placeholder>
      )
    } else {
      return (
        <Placeholder>
          <div>{t('ontimePdfError.message')}</div>
        </Placeholder>
      )
    }
  }
}

export default withTranslation()(PdfViewer)
