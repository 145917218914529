import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Invoice from '../../../common/types/Invoice'
import { InvoiceDetailsPanel, InvoiceRow } from '../common/invoiceElements'
import { FormatAmount, FormatDate } from '../../../common/formatters'
import { InvoiceMoreDetailsHeader } from './InvoiceMoreDetails.style'

interface InvoiceMoreDetailsProps {
  invoice: Invoice
}

const InvoiceMoreDetails = ({ invoice }: InvoiceMoreDetailsProps) => {
  const { t } = useTranslation()

  return (
    <InvoiceDetailsPanel>
      <InvoiceMoreDetailsHeader>
        <div>
          <h4>
            <FormatAmount>{invoice.invoiceDetails.total}</FormatAmount> €
          </h4>
          <span>{t('toPay.label')}</span>
        </div>
        <div>
          <h4>
            <FormatDate>{invoice.invoiceDetails.dueDate}</FormatDate>
          </h4>
          <span>{t('dueDate.label')}</span>
        </div>
      </InvoiceMoreDetailsHeader>
      <InvoiceRow size="big">
        <label>{t('invoiceNumber.label')}</label>
        <span>{invoice.customData.invoiceNumber}</span>
      </InvoiceRow>
      <InvoiceRow size="big">
        <label>{t('creditNumber.label')}</label>
        <span>{invoice.customData.loanNumber}</span>
      </InvoiceRow>
      <hr />
      <InvoiceRow vertical size="big">
        <label>{t('creditOpenAmount.label')}</label>
        <span>
          <FormatAmount>{invoice.invoiceDetails.principalRemaining}</FormatAmount>
        </span>
      </InvoiceRow>
      {!!invoice.invoiceDetails.principalPayment && (
        <InvoiceRow vertical size="big">
          <label>{t('creditAmountAfterPayment.label')}</label>
          <span>
            <FormatAmount>
              {invoice.invoiceDetails.principalRemaining - invoice.invoiceDetails.principalPayment}
            </FormatAmount>
          </span>
        </InvoiceRow>
      )}
      {!!invoice.invoiceDetails.principalPayment && (
        <InvoiceRow vertical size="big">
          <label>{t('principalPayment.label')}</label>
          <span>
            <FormatAmount>{invoice.invoiceDetails.principalPayment}</FormatAmount>
          </span>
        </InvoiceRow>
      )}
      {!!invoice.invoiceDetails.interest && (
        <InvoiceRow vertical size="big">
          <label>{t('interest.label')}</label>
          <span>
            <FormatAmount>{invoice.invoiceDetails.interest}</FormatAmount>
          </span>
        </InvoiceRow>
      )}
      {!!invoice.invoiceDetails.reminderFee && (
        <InvoiceRow vertical size="big">
          <label>{t('reminderFee.label')}</label>
          <span>
            <FormatAmount>{invoice.invoiceDetails.reminderFee}</FormatAmount>
          </span>
        </InvoiceRow>
      )}
      {!!invoice.invoiceDetails.handlingFee && (
        <InvoiceRow vertical size="big">
          <label>{t('handlingFee.label')}</label>
          <span>
            <FormatAmount>{invoice.invoiceDetails.handlingFee}</FormatAmount>
          </span>
        </InvoiceRow>
      )}
      {!!invoice.invoiceDetails.otherFee && (
        <InvoiceRow vertical size="big">
          <label>{t('otherFee.label')}</label>
          <span>
            <FormatAmount>{invoice.invoiceDetails.otherFee}</FormatAmount>
          </span>
        </InvoiceRow>
      )}
      <InvoiceRow vertical size="big">
        <label>{t('invoicingDate.label')}</label>
        <span>
          <FormatDate>{invoice.invoiceDetails.invoiceDate}</FormatDate>
        </span>
      </InvoiceRow>
      <hr />
    </InvoiceDetailsPanel>
  )
}

export default InvoiceMoreDetails
