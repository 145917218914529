import styled from 'styled-components/macro'

export const Header = styled.h4`
  padding-left: 20px;
  padding-top: 12px;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: ${(props) => props.theme.text};
  margin: 0;
`

export const Divider = styled.hr`
  && {
    background-color: #ccc;
    height: 2px;
    margin: 11px 5px 17px;
  }
`

export const Total = styled.div`
  font-weight: 600;
  font-style: italic;
  font-size: 50px;
  line-height: 64px;
  margin-top: 5px;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => props.theme.heading};
`

export const TotalLabel = styled.label`
  display: block;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${(props) => props.theme.text};
  margin-bottom: 19px;
`

export const PdfButton = styled.button.attrs({ type: 'button' })`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  color: ${(props) => props.theme.button};

  & img {
    margin-left: 7px;
    margin-bottom: -3px;
  }
  &:hover {
    cursor: pointer;
  }
`
