import * as React from 'react'
import { PrimaryButtonAlternate } from '../../../components/buttons'
import Spinner from './Spinner'
import { useTranslation } from 'react-i18next'
import { ForwardArrowIcon } from '../../../components/ButtonIcon.component/IconSvg'
import { IconSize, IconColor } from '../../../common/const'

interface PayButtonProps {
  handleOnPay: () => void
  isDisabled: boolean
  label?: string
}
const PayButton = ({ handleOnPay, label, isDisabled }: PayButtonProps) => {
  const { t } = useTranslation()

  return (
    <PrimaryButtonAlternate onClick={handleOnPay} disabled={isDisabled}>
      {isDisabled ? (
        <Spinner />
      ) : (
        <>
          {t(label || 'ontimePayInvoiceButton.label')}
          <ForwardArrowIcon color={IconColor.SECONDARY} size={IconSize.SMALL} />
        </>
      )}
    </PrimaryButtonAlternate>
  )
}

export default PayButton
