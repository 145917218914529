import * as React from 'react'
import NotProdBanner from './components/NotProdBanner/NotProdBanner.component'
import OntimeApp from './OntimeApp'
import InkassoApp from './InkassoApp'
import { UI } from './common/const'
import { Provider } from 'react-redux'
import store from './common/store'

// the frontend supports multiple completely independent UIs, although backend API, state, models code is shared
// UI may come from invoice, but there is also default UI coming from environment variable, used for login UI,
// not found page etc.  Normally these should be equal
const ui =
  store.getState().invoice?.invoiceData?.templateConfiguration.invoiceType ||
  process.env.REACT_APP_UI ||
  UI.ONTIME

export const App = () => {
  return (
    <Provider store={store}>
      <NotProdBanner />
      {(() => {
        switch (ui) {
          case UI.ONTIME:
            return <OntimeApp />
          case UI.INKASSO:
            return <InkassoApp />
          default:
            return <h2 style={{ textAlign: 'center' }}>Missing/unknown UI type</h2>
        }
      })()}
    </Provider>
  )
}
