import { DateTime } from 'luxon'
import { CurrencySeparator, CurrencySeparatorChar, toCurrencySeparatorChar } from './const'

export const formatDate = (str: string, format: string | null) => {
  const date = new Date(str)
  if (format) {
    return DateTime.fromJSDate(date).toFormat(format)
  }
  return date.toLocaleDateString()
}

export const formatAmount = (
  amount: number,
  thousandSeparator: CurrencySeparator,
  decimalSeparator: CurrencySeparator
) => {
  // Make 2-digit decimal and place the thousand seperator E.g. "1234.00", "1.234.00", "1,234.00", "1 234.00"
  const thousandSeparatorChar = toCurrencySeparatorChar(thousandSeparator)
  const thousandsRegex = /\B(?=(\d{3})+(?!\d))/g
  let formattedAmount = amount.toFixed(2).replace(thousandsRegex, thousandSeparatorChar)
  const convertedDecimalSeparator = toCurrencySeparatorChar(decimalSeparator)
  // The decimalSeparator shouldn't be an empty string, we want to change it to a comma in this case.
  const decimalSeparatorChar =
    convertedDecimalSeparator !== CurrencySeparatorChar.NONE
      ? convertedDecimalSeparator
      : CurrencySeparatorChar.COMMA
  // Decimal Seperator is always in the 3rd index from last
  const decimalIndex = formattedAmount.length - 3
  formattedAmount = `${formattedAmount.substring(
    0,
    decimalIndex
  )}${decimalSeparatorChar}${formattedAmount.substring(decimalIndex + 1)}`
  return formattedAmount
}

export const parseAmountToFloat = (amount: string | number) =>
  typeof amount === 'string' ? parseFloat(amount.replace(',', '.')) : amount
