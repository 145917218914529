import * as React from 'react'
import { InformationIcon } from '../common/icons'
import { Disclaimer } from './DisclaimerLabel.style'

interface DisclaimerProps {
  disclaimerText: string
  margin?: string
}
const DisclaimerLabel = ({ disclaimerText, margin }: DisclaimerProps) => {
  return (
    <Disclaimer margin={margin}>
      <InformationIcon />
      <p>{disclaimerText}</p>
    </Disclaimer>
  )
}

export default DisclaimerLabel
