import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Invoice from '../../../common/types/Invoice'
import { InvoiceDetailsPanel, InvoiceRow } from '../common/invoiceElements'

interface InvoicePayeeDetailsProps {
  invoice: Invoice
}

const InvoicePayeeDetails = ({ invoice }: InvoicePayeeDetailsProps) => {
  const { t } = useTranslation()

  return (
    <InvoiceDetailsPanel>
      <h3>{t('invoicerInformation.label')}</h3>
      <InvoiceRow size="big">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          SveaDirekt
          <br />
          Mechelininkatu 1A 00180 Helsinki
          <br />
          Puh: (09) 4242 3090
          <br />
          Y-tunnus: 3237195-7
          <br />
          <br />
        </label>
      </InvoiceRow>
      <InvoiceRow size="big">
        <label>{t('invoicer.label')}</label>
        <span>{invoice.invoicerDetails.companyName}</span>
      </InvoiceRow>
      <hr />
      <InvoiceRow size="big">
        <label>{t('ontimeAccNo.label')}</label>
        <span>{invoice.invoiceDetails.iban}</span>
      </InvoiceRow>
      <hr />
      <InvoiceRow size="big">
        <label>{t('ontimeRefNo.label')}</label>
        <span>{invoice.invoiceDetails.reference}</span>
      </InvoiceRow>
      <hr />
      <InvoiceRow size="big">
        <label>{t('ontimeBIC.label')}</label>
        <span>{invoice.invoiceDetails.bic}</span>
      </InvoiceRow>
    </InvoiceDetailsPanel>
  )
}

export default InvoicePayeeDetails
