import * as React from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import SveaFooter from '../Footer/Footer.component'
import { RootState } from '../../../common/store'
import { LoggedOutLayout, LoggedOutMain } from '../LoggedOutElements/loggedOutElements.style'
import { LoggedOutLogo } from '../LoggedOutElements/loggedOutElements.component'
import { ThemeProvider } from 'styled-components/macro'
import LoginButton from '../common/LoginButton'
import { getThemeFromState } from '../../../common/themes/theme'
import GoBackButton from '../common/GoBackButton'

type AuthThirdPartyConnectedProps = ConnectedProps<typeof connector>
interface AuthThirdPartyProps extends AuthThirdPartyConnectedProps {
  onStartAuth?: () => void
}

const AuthThirdParty = ({ authPending, onStartAuth }: AuthThirdPartyProps) => {
  const theme = getThemeFromState()
  const invoice = useSelector((state: RootState) => state.invoice)
  const invoiceId = invoice.invoiceId
  const isAuthenticationRequired = () => {
    return !invoice.invoiceData || invoice.invoiceData?.requireAuthentication
  }

  return (
    <ThemeProvider theme={theme.auth}>
      <LoggedOutLayout>
        <LoggedOutMain>
          <LoggedOutLogo {...theme.logo} />
          <LoginButton handleOnStartAuth={onStartAuth} authPending={authPending} />
          {!isAuthenticationRequired() && <GoBackButton invoiceId={invoiceId} />}
        </LoggedOutMain>
        <SveaFooter />
      </LoggedOutLayout>
    </ThemeProvider>
  )
}

const connector = connect((state: RootState) => ({ authPending: state.auth.authPending }))
export default connector(AuthThirdParty)
