import * as React from 'react'
import { Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components/macro'
import themes from './common/themes/themes'
import MaintenancePage from './inkasso/Maintenance.page'
import AuthPage from './inkasso/Auth.page'
import CallbackPage from './inkasso/Callback.page'
import LogoutPage from './inkasso/Logout.page'
import NotAuthorizedPage from './inkasso/NotAuthorized.page'
import InvoicePage from './inkasso/Invoice.page'
import PayPage from './inkasso/Pay.page'
import './common/i18n'

function InkassoApp() {
  if (process.env.REACT_APP_MAINTENANCE === 'true') {
    return (
      <ThemeProvider theme={themes.inkasso}>
        <div className="App">
          <MaintenancePage />
        </div>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={themes.inkasso}>
      <Router>
        <div className="App">
          <Suspense fallback="">
            <Switch>
              <Route path="/logout" exact component={LogoutPage} />
              <Route path="/callback" exact component={CallbackPage} />
              <Route path="/notAuthorized" exact component={NotAuthorizedPage} />
              <Route path="/maintenance" exact component={MaintenancePage} />
              <Route path="/:invoiceId/pay" exact component={PayPage} />
              <Route path="/:invoiceId/auth" exact component={AuthPage} />
              <Route path="/:invoiceId" exact component={InvoicePage} />
            </Switch>
          </Suspense>
        </div>
      </Router>
    </ThemeProvider>
  )
}

export default InkassoApp
