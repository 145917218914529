import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { InvoiceRow } from '../common/invoiceElements'
import { ClockIcon } from '../common/icons'
import InvoiceDetailsCopy from '../InvoiceDetailsCopy/InvoiceDetailsCopy'
import { Header, Divider, Total, TotalLabel } from './InvoiceDetails.style'
import { InvoiceDetailsPanel } from '../common/invoiceElements'
import { FormatAmount, FormatDate } from '../../../common/formatters'
import { Interaction } from '../../../common/const'

export interface InvoiceDetailsSerbiaProps {
  detailsLabel?: string
  recipientLabel?: string
  detailsAllowCopy?: boolean
  companyName: string
  bankLabel?: string
  bank: string
  accountNumberLabel?: string
  iban: string
  referenceNumberLabel?: string
  reference: string
  total: number
  currency?: string
  amountToPayLabel?: string
  dueDate: string
  dueDateLabel?: string
}

const InvoiceDetailsSerbia = (invoiceDetailsSerbiaProps: InvoiceDetailsSerbiaProps) => {
  const { t } = useTranslation()

  return (
    <InvoiceDetailsPanel>
      <Header>{t(invoiceDetailsSerbiaProps.detailsLabel || 'ontimePayInfo.label')}</Header>
      <Divider />
      <InvoiceRow size="small">
        <label>{t(invoiceDetailsSerbiaProps.recipientLabel || 'ontimeRecipient.label')}</label>
        <span>
          {invoiceDetailsSerbiaProps.detailsAllowCopy && (
            <InvoiceDetailsCopy value={invoiceDetailsSerbiaProps.companyName} />
          )}
          {invoiceDetailsSerbiaProps.companyName}
        </span>
      </InvoiceRow>
      <InvoiceRow size="small">
        <label>{t(invoiceDetailsSerbiaProps.accountNumberLabel || 'ontimeAccNo.label')}</label>
        <span>
          {invoiceDetailsSerbiaProps.detailsAllowCopy && (
            <InvoiceDetailsCopy
              value={invoiceDetailsSerbiaProps.iban}
              interaction={Interaction.IBAN_COPIED}
            />
          )}
          {invoiceDetailsSerbiaProps.iban}
        </span>
      </InvoiceRow>
      <InvoiceRow size="small">
        <label>{t(invoiceDetailsSerbiaProps.bankLabel || 'ontimeBank.label')}</label>
        <span>
          {invoiceDetailsSerbiaProps.detailsAllowCopy && (
            <InvoiceDetailsCopy value={invoiceDetailsSerbiaProps.bank} />
          )}
          {invoiceDetailsSerbiaProps.bank}
        </span>
      </InvoiceRow>
      <InvoiceRow size="small">
        <label>{t(invoiceDetailsSerbiaProps.referenceNumberLabel || 'ontimeRefNo.label')}</label>
        <span>
          {invoiceDetailsSerbiaProps.detailsAllowCopy && (
            <InvoiceDetailsCopy
              value={invoiceDetailsSerbiaProps.reference}
              interaction={Interaction.REFNR_COPIED}
            />
          )}
          {invoiceDetailsSerbiaProps.reference}
        </span>
      </InvoiceRow>
      <Total>
        <FormatAmount>{invoiceDetailsSerbiaProps.total}</FormatAmount>{' '}
        {invoiceDetailsSerbiaProps.currency || '€'}
      </Total>
      <TotalLabel>
        {t(invoiceDetailsSerbiaProps.amountToPayLabel || 'ontimeToPay.label')}
      </TotalLabel>
      <TotalLabel>
        <ClockIcon />
        &nbsp;{t(invoiceDetailsSerbiaProps.dueDateLabel || 'ontimeDueDate.label')}&nbsp;
        <b>
          <FormatDate>{invoiceDetailsSerbiaProps.dueDate}</FormatDate>
        </b>
      </TotalLabel>
    </InvoiceDetailsPanel>
  )
}

export default InvoiceDetailsSerbia
