import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { InvoiceRow } from '../common/invoiceElements'
import { Divider, Total, TotalLabel } from './InvoiceDetails.style'
import { InvoiceDetailsPanel } from '../common/invoiceElements'
import InvoiceDetailsCommon, { InvoiceDetailsCommonProps } from './InvoiceDetailsCommon'
import styled from 'styled-components/macro'
import { FormatAmount } from '../../../common/formatters'
import PdfButtonIcon from '../common/PdfButtonIcon'
import DisclaimerLabel from '../DisclaimerLabel/DisclaimerLabel.component'

export interface InvoiceDetailsMinPayProps extends InvoiceDetailsCommonProps {
  total: number
  minimumAmount: number
  openAmount: number
  totalCreditLabel?: string
  noPdf?: boolean
  pdfLinkLabel?: string
  onShowPdf?: () => void
  isAuthenticated: boolean
}

export const DividerShort = styled.hr`
  && {
    background-color: #ccc;
    height: 2px;
    margin: 0 auto;
    width: 30%;
  }
`

const InvoiceDetailsMinPay = (invoiceDetailsMinPayProps: InvoiceDetailsMinPayProps) => {
  const { t } = useTranslation()
  const isAuthenticated = invoiceDetailsMinPayProps.isAuthenticated
  const invoiceCommonDetailsProps: InvoiceDetailsCommonProps = {
    detailsLabel: invoiceDetailsMinPayProps.detailsLabel,
    dueDateLabel: invoiceDetailsMinPayProps.dueDateLabel,
    dueDate: invoiceDetailsMinPayProps.dueDate,
    recipientLabel: invoiceDetailsMinPayProps.recipientLabel,
    detailsAllowCopy: invoiceDetailsMinPayProps.detailsAllowCopy,
    companyName: invoiceDetailsMinPayProps.companyName,
    accountNumberLabel: invoiceDetailsMinPayProps.accountNumberLabel,
    iban: invoiceDetailsMinPayProps.iban,
    referenceNumberLabel: invoiceDetailsMinPayProps.referenceNumberLabel,
    reference: invoiceDetailsMinPayProps.reference,
    bicLabel: invoiceDetailsMinPayProps.bicLabel,
    bic: invoiceDetailsMinPayProps.bic
  }

  const minimumAmount = invoiceDetailsMinPayProps.minimumAmount || 0

  return (
    <InvoiceDetailsPanel>
      <InvoiceDetailsCommon {...invoiceCommonDetailsProps} />
      <Total>
        <FormatAmount>{minimumAmount}</FormatAmount> €{' '}
      </Total>
      <TotalLabel>{t('type24AmountToPayMinimum.label')}</TotalLabel>
      <DividerShort />
      <Total>
        <FormatAmount>{invoiceDetailsMinPayProps.total}</FormatAmount> €
      </Total>
      <TotalLabel>{t('ontimeInvoiceAmountDue.label')}</TotalLabel>
      {!!invoiceDetailsMinPayProps.openAmount && (
        <>
          <Divider />
          <InvoiceRow size="small">
            <label>
              {t(invoiceDetailsMinPayProps.totalCreditLabel || 'ontimeOpenInvoiceAmount.label')}
            </label>
            <span>
              <FormatAmount>{invoiceDetailsMinPayProps.openAmount}</FormatAmount> €
            </span>
          </InvoiceRow>
          {!invoiceDetailsMinPayProps.noPdf && (
            <>
              <InvoiceRow size="small">
                <label>
                  {t(invoiceDetailsMinPayProps.pdfLinkLabel || 'ontimeAsPdfButton.label')}
                </label>
                <span>
                  <PdfButtonIcon
                    handleOnShowPdf={invoiceDetailsMinPayProps.onShowPdf}
                    isAuthenticated={isAuthenticated}
                  />
                </span>
              </InvoiceRow>
              {!isAuthenticated && (
                <InvoiceRow size="small">
                  <DisclaimerLabel disclaimerText={t('pdfNotAuthenticatedMinimumAmount.label')} />
                </InvoiceRow>
              )}
            </>
          )}
        </>
      )}
    </InvoiceDetailsPanel>
  )
}

export default InvoiceDetailsMinPay
