import * as React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  setAuthError,
  setAuthPending,
  startAuth,
  setAuthRedirectUrl,
  authCompleted,
  setSessionId
} from '../../common/state/auth'
import { setInvoiceId } from '../../common/state/invoice'
import { setMaintenance } from '../../common/state/common'
import backend from '../../common/backend'
import AuthThirdParty from '../components/AuthThirdParty/AuthThirdParty.component'
import AuthBirthday from '../components/AuthBirthday/AuthBirthday.component'
import AuthError from '../components/AuthError/AuthError.component'
import MaintenancePage from './Maintenance.page'
import { RootState } from '../../common/store'
import { InvoicerAuthMethod } from '../../common/const'
import { getThemeFromState } from '../../common/themes/theme'

type OntimeAuthPageConnectedProps = ConnectedProps<typeof connector>

interface OntimeAuthPageState {
  // birthday auth
  day: number
  month: number
  year: number
  themeName?: string
}

class AuthPage extends React.Component<
  OntimeAuthPageConnectedProps & WithTranslation & RouteComponentProps<{ invoiceId: string }>,
  OntimeAuthPageState
> {
  constructor(
    props: OntimeAuthPageConnectedProps &
      WithTranslation &
      RouteComponentProps<{ invoiceId: string }>
  ) {
    super(props)
    this.state = {
      day: 1,
      month: 1,
      year: new Date().getFullYear() - 13
    }
  }

  componentDidMount() {
    //Clear errors from auth pages so jumping back and forth works
    this.props.setAuthError('')
    this.props.setAuthPending(false)
    this.props.setInvoiceId(this.props.match.params.invoiceId)
    // signal invoice open to backend; fire and forget, no need to await
    backend
      .logInvoiceOpen(this.props.match.params.invoiceId)
      .catch((reason) => console.warn('Failed to log invoice open'))
  }

  startAuthExternal() {
    const { t } = this.props
    this.props.setAuthPending(true)
    backend
      .postAuthForRedirect(this.props.match.params.invoiceId)
      .then(async (response) => {
        this.props.startAuth({
          sessionId: response.data.sessionId,
          authUrl: response.data.authUrl,
          authPending: true
        })
        this.props.setAuthRedirectUrl(response.data.authUrl)
      })
      .catch((err) => {
        if (err.response.status === 503) {
          this.props.setMaintenance()
        } else {
          console.log('Error response from backend: ', err)
          this.props.setAuthPending(false)
          this.props.setAuthError(t('authStartingError.label'))
        }
      })
  }

  startAuthBirthday() {
    const { t } = this.props
    const { day, month, year } = this.state
    const dateOfBirth = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`
    this.props.setAuthPending(true)
    backend
      .postAuthSimple(this.props.match.params.invoiceId, { dateOfBirth })
      .then(async (response) => {
        this.props.setSessionId(response.data.sessionId)
        this.props.authCompleted({ jwtToken: response.data.token })
      })
      .catch((err) => {
        if (err.response.status === 503) {
          this.props.setMaintenance()
        } else {
          console.log('Error response from backend: ', err)
          this.props.setAuthPending(false)
          this.props.setAuthError(t('invoiceMissing.label'))
        }
      })
  }

  render() {
    const invoiceId = this.props.match.params.invoiceId
    const { day, month, year } = this.state

    if (this.props.jwtToken && this.props.sessionId) {
      return <Redirect to={`/${invoiceId}`} />
    }

    if (process.env.REACT_APP_MAINTENANCE === 'soft') {
      return <MaintenancePage />
    }

    if (this.props.authRedirectUrl) {
      window.location.href = this.props.authRedirectUrl
    }

    if (this.props.authError) {
      return <AuthError />
    }

    switch (getThemeFromState().authMethod) {
      case InvoicerAuthMethod.PERSONAL_DATA_BIRTHDAY:
        return (
          <AuthBirthday
            day={day}
            month={month}
            year={year}
            onSetDay={(day: number) => this.setState({ day })}
            onSetMonth={(month: number) => this.setState({ month })}
            onSetYear={(year: number) => this.setState({ year })}
            onAuth={() => this.startAuthBirthday()}
          />
        )

      default:
        return <AuthThirdParty onStartAuth={() => this.startAuthExternal()} />
    }
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    sessionId: state.auth.sessionId,
    authPending: state.auth.authPending,
    authError: state.auth.errorMessage,
    jwtToken: state.auth.jwtToken,
    authRedirectUrl: state.auth.redirectUrl,
    invoiceId: state.invoice.invoiceId
  }
}

const connector = connect(mapStateToProps, {
  setAuthError,
  setAuthPending,
  startAuth,
  setSessionId,
  setAuthRedirectUrl,
  authCompleted,
  setInvoiceId,
  setMaintenance
})
export default connector(withTranslation()(AuthPage))
