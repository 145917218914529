import * as React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

const CopyButton = styled.button.attrs({ type: 'button' })`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin-right: 8px;
`
interface InvoiceDetailsCopyButtonProps {
  handleClick: (value: string) => void
  value: string
}

const InvoiceDetailsCopyButton = ({ handleClick, value }: InvoiceDetailsCopyButtonProps) => {
  const { t } = useTranslation()
  return (
    <CopyButton data-testid="copy-to-clipboard" onClick={() => handleClick(value)}>
      <img src="/img/copy.svg" alt={t('copy.label')!} title={t('copy.label')!} />
    </CopyButton>
  )
}

export default InvoiceDetailsCopyButton
