import * as React from 'react'
import MoonLoader from 'react-spinners/MoonLoader'
import { SpinnerWrapper, SpinnerWrapperText } from './InvoiceLoadingSpinner.style'

const InvoiceLoadingSpinner = ({ text }: { text: string }) => {
  return (
    <SpinnerWrapper>
      <MoonLoader size={50} color={'#000000'} loading={true} />
      <SpinnerWrapperText>{text}</SpinnerWrapperText>
    </SpinnerWrapper>
  )
}

export default InvoiceLoadingSpinner
