import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

interface AuthState {
  jwtToken: string | null
  sessionId: string | null
  authUrl: string | null
  redirectUrl: string | null
  authPending: boolean
  errorMessage: string | null
  statusMessage: string | null
}

const INITIAL_STATE: AuthState = {
  jwtToken: null,
  sessionId: null,
  authUrl: null,
  redirectUrl: null,
  authPending: false,
  errorMessage: null,
  statusMessage: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setAuthPending: (state: Draft<AuthState>, action: PayloadAction<boolean>) => {
      state.authPending = action.payload
    },

    startAuth: (
      state: Draft<AuthState>,
      action: PayloadAction<{
        sessionId: string | null
        authUrl: string | null
        authPending: boolean
      }>
    ) => {
      state.authPending = action.payload.authPending
      state.authUrl = action.payload.authUrl
      state.sessionId = action.payload.sessionId
      state.errorMessage = null
    },

    setAuthError: (state: Draft<AuthState>, action: PayloadAction<string>) => {
      state.errorMessage = action.payload
    },

    authCompleted: (state: Draft<AuthState>, action: PayloadAction<{ jwtToken: string }>) => {
      state.jwtToken = action.payload.jwtToken
      state.authPending = false
      state.errorMessage = null
    },

    clearAuth: (state: Draft<AuthState>) => {
      state.jwtToken = null
      state.sessionId = null
    },

    setAuthToken: (state: Draft<AuthState>, action: PayloadAction<string | null>) => {
      state.jwtToken = action.payload
    },

    setAuthRedirectUrl: (state: Draft<AuthState>, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload
    },

    setSessionId: (state: Draft<AuthState>, action: PayloadAction<string | null>) => {
      state.sessionId = action.payload
    }
  }
})

export const {
  setAuthPending,
  startAuth,
  setAuthError,
  authCompleted,
  clearAuth,
  setAuthToken,
  setAuthRedirectUrl,
  setSessionId
} = authSlice.actions
export default authSlice.reducer
