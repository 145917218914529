import styled from 'styled-components/macro'

export const Container = styled.div`
  background: white;
  min-height: 100%;
  min-width: 360px;
`

export const Placeholder = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
`
