import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { InvoiceRow } from '../common/invoiceElements'
import InvoiceDetailsCopy from '../InvoiceDetailsCopy/InvoiceDetailsCopy'
import { Header, Divider } from './InvoiceDetails.style'
import { FormatDate } from '../../../common/formatters'

export interface InvoiceDetailsCommonProps {
  detailsLabel?: string
  dueDateLabel?: string
  dueDate: string
  recipientLabel?: string
  detailsAllowCopy?: boolean
  companyName: string
  accountNumberLabel?: string
  iban: string
  referenceNumberLabel?: string
  reference: string
  bicLabel?: string
  bic: string
}

const InvoiceDetailsCommon = (invoiceDetailsCommonProps: InvoiceDetailsCommonProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Header>{t(invoiceDetailsCommonProps.detailsLabel || 'ontimePayInfo.label')}</Header>
      <Divider />
      <InvoiceRow size="small">
        <label>{t(invoiceDetailsCommonProps.dueDateLabel || 'ontimeDueDate.label')}</label>
        <span id="ontimeInvoiceDueDate">
          <FormatDate>{invoiceDetailsCommonProps.dueDate}</FormatDate>
        </span>
      </InvoiceRow>
      <InvoiceRow size="small">
        <label>{t(invoiceDetailsCommonProps.recipientLabel || 'ontimeRecipient.label')}</label>
        <span>
          {invoiceDetailsCommonProps.detailsAllowCopy && (
            <InvoiceDetailsCopy value={invoiceDetailsCommonProps.companyName} />
          )}
          {invoiceDetailsCommonProps.companyName}
        </span>
      </InvoiceRow>
      <InvoiceRow size="small">
        <label>{t(invoiceDetailsCommonProps.accountNumberLabel || 'ontimeAccNo.label')}</label>
        <span>
          {invoiceDetailsCommonProps.detailsAllowCopy && (
            <InvoiceDetailsCopy value={invoiceDetailsCommonProps.iban} />
          )}
          {invoiceDetailsCommonProps.iban}
        </span>
      </InvoiceRow>
      <InvoiceRow size="small">
        <label>{t(invoiceDetailsCommonProps.referenceNumberLabel || 'ontimeRefNo.label')}</label>
        <span>
          {invoiceDetailsCommonProps.detailsAllowCopy && (
            <InvoiceDetailsCopy value={invoiceDetailsCommonProps.reference} />
          )}
          {invoiceDetailsCommonProps.reference}
        </span>
      </InvoiceRow>
      <InvoiceRow size="small">
        <label>{t(invoiceDetailsCommonProps.bicLabel || 'ontimeBIC.label')}</label>
        <span>
          {invoiceDetailsCommonProps.detailsAllowCopy && (
            <InvoiceDetailsCopy value={invoiceDetailsCommonProps.bic} />
          )}
          {invoiceDetailsCommonProps.bic}
        </span>
      </InvoiceRow>
    </>
  )
}

export default InvoiceDetailsCommon
