import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton, SecondaryButton } from '../../../components/buttons'
import { Message } from '../common/invoiceElements'
import { BigErrorIcon } from '../common/icons'

const PaymentError = ({ invoiceId }: { invoiceId: string }) => {
  const { t } = useTranslation()

  return (
    <Message>
      <BigErrorIcon alt={t('error.label')!} />
      <h2>{t('paymentFailed.label')}</h2>
      <PrimaryButton onClick={() => (window.location.href = '/' + invoiceId + '/payAction/retry')}>
        {t('tryAgain.label')}
      </PrimaryButton>
      <SecondaryButton onClick={() => (window.location.href = '/' + invoiceId)}>
        {t('cancel.label')}
      </SecondaryButton>
    </Message>
  )
}

export default PaymentError
