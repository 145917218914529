import * as React from 'react'
import { getI18n } from 'react-i18next'
import { Langs } from './MarketingDataFetcher'
import { MarketingBannerData } from './MarketingDataFetcher'
import { Wrapper, Banner } from './MarketingBanner.style'

export interface MarketingBannerProps {
  marketingData: MarketingBannerData
  handleClick: () => void
}

const MarketingBanner = ({ marketingData, handleClick }: MarketingBannerProps) => {
  const lang = getI18n().language as keyof Langs

  return (
    <Wrapper>
      <Banner
        onClick={handleClick}
        src={
          marketingData.banner[lang] === undefined
            ? marketingData.banner[Object.keys(marketingData.banner)[0] as keyof Langs]
            : marketingData.banner[lang]
        }
      />
    </Wrapper>
  )
}

export default MarketingBanner
