import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Heading = styled.h2`
  margin: 0 auto 50px;
  font-size: 24px;
  line-height: 40px;
  font-weight: 600;
  font-style: italic;
  text-align: center;
`

export const Image = styled.img`
  width: 11.375rem;
`
