import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import { Interaction } from '../../../common/const'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../../common/store'
import { logInteraction } from '../../../common/backend'
import InvoiceDetailsCopyButton from './InvoiceDetailsCopyButton'
import SnackBarCloseButton from './SnackBarCloseButton'

type InvoiceDetailsCopyConnectedProps = ConnectedProps<typeof connector>

interface InvoiceDetailsCopyProps {
  value: string
  interaction?: Interaction
}

const InvoiceDetailsCopy = ({
  value,
  interaction,
  invoiceId
}: InvoiceDetailsCopyProps & InvoiceDetailsCopyConnectedProps) => {
  const [isSnackbarOpen, setSnackbarOpen] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')

  const copyToClipBoard = (value: string) => {
    navigator.clipboard.writeText(value)
    setSnackbarMessage(value)
    setSnackbarOpen(true)
    handleLogInteraction()
  }

  const handleLogInteraction = () => {
    if (invoiceId && interaction) {
      logInteraction(invoiceId, interaction) // no await, fire and forget
    }
  }

  const handleSnackBarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
    setSnackbarMessage('')
  }

  return (
    <>
      <InvoiceDetailsCopyButton handleClick={copyToClipBoard} value={value} />
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        message={snackbarMessage}
        action={<SnackBarCloseButton handleClick={handleSnackBarClose} />}
        data-testid="snackbar-invoice-details-copy"
      />
    </>
  )
}

const connector = connect((state: RootState) => ({ invoiceId: state.invoice.invoiceId }))

export default connector(InvoiceDetailsCopy)
