import * as React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import backend from '../../common/backend'
import { authCompleted, setAuthPending, setAuthError, setSessionId } from '../../common/state/auth'
import Auth from '../components/AuthThirdParty/AuthThirdParty.component'
import AuthError from '../components/AuthError/AuthError.component'
import { RootState } from '../../common/store'

type CallbackPageConnectedProps = ConnectedProps<typeof connector>

class CallbackPage extends React.Component<
  CallbackPageConnectedProps & WithTranslation & RouteComponentProps,
  { redirect: string | null }
> {
  constructor(props: CallbackPageConnectedProps & WithTranslation & RouteComponentProps) {
    super(props)
    this.state = { redirect: null }
  }

  componentDidMount() {
    const { t, setAuthPending } = this.props
    const queryParams = new URLSearchParams(this.props.location.search)
    const sessionId = queryParams.get('state')
    // should not happen
    if (!sessionId) {
      document.location.href = '/'
      return
    }
    setAuthPending(true)
    backend
      .getAuth(sessionId, this.props.location.search.replace(/^\?/, ''))
      .then((response) => {
        if (response.data.status === 'completed') {
          this.props.authCompleted({ jwtToken: response.data.token })
          this.props.setSessionId(sessionId)
          this.setState({ redirect: response.data.redirectUrl })
        } else {
          console.log(response)
          this.props.setAuthError(t('authorizationError.label') + ': ' + response.data.errorText)
        }
      })
      .catch((e) => {
        console.log('Error calling getAuth', e)
        this.props.setAuthError(t('authorizationError.label'))
      })
  }

  render() {
    const { redirect } = this.state

    if (redirect) {
      document.location.href = redirect
    }

    if (this.props.authError) {
      return <AuthError />
    }

    return <Auth />
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    authError: state.auth.errorMessage
  }
}

const connector = connect(mapStateToProps, {
  authCompleted,
  setAuthPending,
  setAuthError,
  setSessionId
})
export default connector(withTranslation()(CallbackPage))
