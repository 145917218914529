import styled from 'styled-components/macro'

export const Wrapper1 = styled.div<{ type: 'mobile' | 'desktop' }>`
  margin-top: ${(props) => (props.type === 'mobile' ? '302px' : '60px')};
  margin-bottom: ${(props) => (props.type === 'mobile' ? '124px' : '42px')};
`

export const Wrapper2 = styled.div<{ type: 'mobile' | 'desktop' }>`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.type === 'mobile' ? 'center' : 'flex-start')};
  align-items: ${(props) => (props.type === 'mobile' ? 'center' : 'flex-start')};
  margin-left: ${(props) => (props.type === 'mobile' ? '0' : '60px')};
  flex-shrink: 0;
`

export const Logo = styled.img`
  height: 70px;
`
