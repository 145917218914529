import { PartialState } from './store'
import { InvoiceStatus } from './const'

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    const deserializedState = JSON.parse(serializedState)
    // invoiceState must always be reset to NOT_LOADED
    // a more sustainable solution would be better in case we need more such overrides
    if (!deserializedState.invoice) {
      deserializedState.invoice = {}
    }
    deserializedState.invoice.invoiceState = InvoiceStatus.NOT_LOADED
    return deserializedState
  } catch (err) {
    console.log(err)
    return undefined
  }
}

export const saveState = (state: PartialState) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (err) {
    console.log('Error saving state to local storage:' + err)
  }
}

export const removeState = () => {
  try {
    localStorage.removeItem('state')
  } catch (err) {
    console.log('Error removing state from local storage:' + err)
  }
}
