import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import Invoice from '../../../common/types/Invoice'
import { InvoiceDetailsPanel, InvoiceRow } from '../common/invoiceElements'
import { ChevronDown, ChevronUp, DoneIcon } from '../common/icons'
import { FormatAmount, FormatDate } from '../../../common/formatters'
import { Details, HeaderExpander, Summary } from './InvoicePaid.style'
import MarketingBanner from '../MarketingBanner/MarketingBanner.component'
import { getMarketingData, MarketingBannerData } from '../MarketingBanner/MarketingDataFetcher'
import { MarketingLinkButton } from '../MarketingBanner/MarketingLinkButton.component'
import { logInteraction } from '../../../common/backend'
import { Interaction } from '../../../common/const'
import DisclaimerLabel from '../DisclaimerLabel/DisclaimerLabel.component'

const InvoicePaid = ({
  invoice,
  storedAmountToBePaid,
  isAuthenticated
}: {
  invoice: Invoice
  storedAmountToBePaid: number | null
  isAuthenticated: boolean
}) => {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(false)
  const [marketingData, setMarketingData] = useState<MarketingBannerData | null>({
    link: '',
    banner: {},
    buttonText: {}
  })
  const [isLoading, setIsLoading] = useState(true)

  const openLinkAndLogInteraction = () => {
    logInteraction(invoice.invoiceId, Interaction.MARKETING_LINK_CLICKED)
    window.open(marketingData?.link, '_blank')
  }

  useEffect(() => {
    const fetchMarketingData = async () => {
      try {
        const marketingData: MarketingBannerData | null = await getMarketingData(invoice.invoiceId)
        setMarketingData(marketingData)
        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching data for marketing banner:', error)
      }
    }

    fetchMarketingData()
  }, [invoice.invoiceId])

  return (
    <>
      <InvoiceDetailsPanel>
        <HeaderExpander onClick={() => setOpen(!isOpen)}>
          <DoneIcon alt="" />
          <div>
            <h3>{t('paymentSucceeded.label')}</h3>
            <p>{t('ontimePayInfo.label')}</p>
          </div>
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </HeaderExpander>
        <Details open={isOpen}>
          <InvoiceRow size="small">
            <label>{t('ontimeDueDate.label')}</label>
            <span id="ontimeInvoiceDueDate">
              <FormatDate>{invoice.invoiceDetails.dueDate}</FormatDate>
            </span>
          </InvoiceRow>
          <InvoiceRow size="small">
            <label>{t('ontimeRecipient.label')}</label>
            <span>{invoice.invoicerDetails.companyName}</span>
          </InvoiceRow>
          <InvoiceRow size="small">
            <label>{t('ontimeAccNo.label')}</label>
            <span>{invoice.invoiceDetails.iban}</span>
          </InvoiceRow>
          <InvoiceRow size="small">
            <label>{t('ontimeRefNo.label')}</label>
            <span>{invoice.invoiceDetails.reference}</span>
          </InvoiceRow>
          <InvoiceRow size="small">
            <label>{t('ontimeBIC.label')}</label>
            <span>{invoice.invoiceDetails.bic}</span>
          </InvoiceRow>
        </Details>
        <Summary>
          <InvoiceRow size="small">
            <label>{t('paidAmount.label')}</label>
            <span>
              <FormatAmount>
                {storedAmountToBePaid || invoice.paidAmount || invoice.invoiceDetails.total}
              </FormatAmount>{' '}
              €
            </span>
          </InvoiceRow>
        </Summary>
        {!isAuthenticated && (
          <DisclaimerLabel disclaimerText={t('invoicePaidDisclaimer.label')} margin="10px 0 0 0" />
        )}
      </InvoiceDetailsPanel>
      {!isLoading && marketingData !== null && (
        <>
          <MarketingBanner marketingData={marketingData} handleClick={openLinkAndLogInteraction} />
          <MarketingLinkButton
            handleClick={openLinkAndLogInteraction}
            buttonText={marketingData.buttonText}
          />
        </>
      )}
    </>
  )
}

export default InvoicePaid
