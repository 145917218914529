import * as React from 'react'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import LogoHeader from '../Header/Header.component'
import SveaFooter from '../Footer/Footer.component'
import Invoice from '../../../common/types/Invoice'
import { InvoiceLayoutWrapper, InvoiceMain, InvoiceTitle } from './InvoiceLayout.style'

interface InvoiceLayoutProps {
  invoice?: Invoice
  fullScreenPopup?: boolean
  title?: string
  titleMode?: 'default' | 'swapped' | 'noOriginalMerchant'
  requireAuthentication?: boolean
}

const InvoiceLayout = ({
  invoice,
  children,
  fullScreenPopup,
  title,
  titleMode,
  requireAuthentication
}: PropsWithChildren<InvoiceLayoutProps>) => {
  const { t } = useTranslation()
  const titleFromInvoice = invoice?.customData.carBrand || invoice?.invoiceDetails.originalMerchant

  return (
    <InvoiceLayoutWrapper fullScreenPopup={fullScreenPopup}>
      <LogoHeader authenticationRequired={requireAuthentication} />
      {invoice && (
        <InvoiceTitle fullScreenPopup={fullScreenPopup}>
          <h1>{!titleMode || titleMode === 'default' ? titleFromInvoice : t(title!)}</h1>
          {!fullScreenPopup && titleMode !== 'noOriginalMerchant' && (
            <h2>{titleMode === 'swapped' ? titleFromInvoice : t(title!)}</h2>
          )}
        </InvoiceTitle>
      )}
      <InvoiceMain fullScreenPopup={fullScreenPopup}>{children}</InvoiceMain>
      {!fullScreenPopup && <SveaFooter invoiceId={invoice?.invoiceId} />}
    </InvoiceLayoutWrapper>
  )
}

export default InvoiceLayout
