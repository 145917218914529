import * as React from 'react'
import { PdfButton } from '../InvoiceDetailsTypes/InvoiceDetails.style'
import { EyeIcon, LockedIcon } from './icons'

interface PdfButtonIconProps {
  handleOnShowPdf?: () => void
  isAuthenticated: boolean
}
const PdfButtonIcon = ({ handleOnShowPdf, isAuthenticated }: PdfButtonIconProps) => {
  return (
    <PdfButton onClick={handleOnShowPdf}>
      PDF
      {isAuthenticated ? <EyeIcon alt="" /> : <LockedIcon alt="" />}
    </PdfButton>
  )
}

export default PdfButtonIcon
