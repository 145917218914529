import * as React from 'react'
import { Footer, Link, LinkWrapper } from './footerLinks.style'

export const SveaLinks = () => (
  <LinkWrapper>
    <Link>
      <a href="https://svea.com">Svea.com</a>
    </Link>
    <Link>
      <a href="https://www.svea.com/se/sv/privat/kontakt">Kontakt</a>
    </Link>
    <Link>
      <a href="https://www.svea.com/se/sv/om-oss/svea-ekonomi/personuppgiftsbehandling">
        Så hanterar vi dina personuppgifter
      </a>
    </Link>
  </LinkWrapper>
)

export const SveaLinksMobile = () => (
  <div>
    <LinkWrapper>
      <Link>
        <a href="https://svea.com">Svea.com</a>
      </Link>
      <Link>
        <a href="https://www.svea.com/se/sv/privat/kontakt">Kontakt</a>
      </Link>
    </LinkWrapper>
    <LinkWrapper>
      <Link>
        <a href="https://www.svea.com/se/sv/om-oss/svea-ekonomi/personuppgiftsbehandling">
          Så hanterar vi dina personuppgifter
        </a>
      </Link>
    </LinkWrapper>
  </div>
)

export const SveaFooter = () => (
  <LinkWrapper>
    <Footer>© 2023 Svea Inkasso AB — org.nr 556214-1423</Footer>
  </LinkWrapper>
)
