import styled from 'styled-components/macro'

export const InvoiceDetailsPanel = styled.div`
  background: ${(props) => props.theme.panel};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: calc(min(100vw - 30px, 400px));
  margin: 10px 0;
  padding-bottom: 15px;

  & h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    margin: 30px 30px 18px;
  }

  & hr {
    background-color: ${(props) => props.theme.divider};
    height: 1px;
    margin: 0 30px 12px 30px;
    border: none;
  }
`

export const MarketingBannerWrapper = styled.div`
  background: ${(props) => props.theme.panel};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: calc(min(100vw - 30px, 400px));
  margin: 10px 0;

  & h3 {
    font-weight: bold;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    margin: 30px 30px 18px;
  }

  & hr {
    background-color: ${(props) => props.theme.divider};
    height: 1px;
    margin: 0 30px 12px 30px;
    border: none;
  }
`

export const InvoiceRow = styled.div<{ vertical?: boolean; size: 'small' | 'big' }>`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  justify-content: space-between;

  & > label {
    display: block;
    margin-left: ${(props) => (props.size === 'big' ? '30px' : '20px')};
    margin-bottom: ${(props) => (props.vertical ? '6px' : '12px')};
    margin-right: auto;
    font-weight: ${(props) => (props.size === 'big' ? 'normal' : '600')};
    font-size: ${(props) => (props.size === 'big' ? '16px' : '14px')};
    line-height: 1.71;
    text-align: left;
    color: ${(props) => props.theme.text};
  }

  & > span {
    display: block;
    margin: ${(props) =>
      props.vertical
        ? '0 auto 16px 30px'
        : `0 ${props.size === 'big' ? '30px' : '20px'} 10px 10px`};
    font-weight: ${(props) => (props.size === 'big' ? '600' : 'normal')};
    font-size: ${(props) => (props.size === 'big' ? '16px' : '14px')};
    line-height: 1.71;
    text-align: ${(props) => (props.vertical ? 'left' : 'right')};
    color: ${(props) => props.theme.text};
  }
`

export const InvoiceButtons = styled.div`
  text-align: center;
  margin-top: 35px;
  margin-bottom: 25px;
`

export const InvoiceButtonLink = styled.button.attrs({ type: 'button' })`
  background: none;
  border: none;
  padding: 0;
  display: inline;
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.button};
  text-align: inherit;
`

export const Message = styled.div`
  background: ${(props) => props.theme.panel};
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  margin: 26px auto auto;
  text-align: center;
  padding: 40px 12px;

  & h2 {
    font-style: italic;
    font-size: 32px;
    line-height: 40px;
    color: ${(props) => props.theme.heading};
    margin-bottom: 30px;
  }

  & button {
    margin-bottom: 10px;
  }
`
