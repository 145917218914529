import React from 'react'
import SvgComponent from './iconSvgReactMapper'
import { IconSizeProps } from '../../common/const'

interface ButtonIconProps {
  svgSource: string
  color: string
  size: IconSizeProps
}

const ButtonIcon = ({ svgSource, color, size }: ButtonIconProps) => {
  return (
    <>
      <SvgComponent svgSource={svgSource} color={color} size={size} />
    </>
  )
}

export default ButtonIcon
