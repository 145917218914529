import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { InvoiceRow } from '../common/invoiceElements'
import { Divider, Total, TotalLabel } from './InvoiceDetails.style'
import { InvoiceDetailsPanel } from '../common/invoiceElements'
import InvoiceDetailsCommon, { InvoiceDetailsCommonProps } from './InvoiceDetailsCommon'
import { FormatAmount } from '../../../common/formatters'
import PdfButtonIcon from '../common/PdfButtonIcon'
import DisclaimerLabel from '../DisclaimerLabel/DisclaimerLabel.component'

export interface InvoiceDetailsCustomPayProps extends InvoiceDetailsCommonProps {
  total: number
  totalMax: number
  totalCreditLabel?: string
  noPdf?: boolean
  pdfLinkLabel?: string
  onShowPdf?: () => void
  isAuthenticated: boolean
}

const InvoiceDetailsCustomPay = (invoiceDetailsCustomPayProps: InvoiceDetailsCustomPayProps) => {
  const { t } = useTranslation()
  const isAuthenticated = invoiceDetailsCustomPayProps.isAuthenticated
  const invoiceDetailsCommonProps: InvoiceDetailsCommonProps = {
    detailsLabel: invoiceDetailsCustomPayProps.detailsLabel,
    dueDateLabel: invoiceDetailsCustomPayProps.dueDateLabel,
    dueDate: invoiceDetailsCustomPayProps.dueDate,
    recipientLabel: invoiceDetailsCustomPayProps.recipientLabel,
    detailsAllowCopy: invoiceDetailsCustomPayProps.detailsAllowCopy,
    companyName: invoiceDetailsCustomPayProps.companyName,
    accountNumberLabel: invoiceDetailsCustomPayProps.accountNumberLabel,
    iban: invoiceDetailsCustomPayProps.iban,
    referenceNumberLabel: invoiceDetailsCustomPayProps.referenceNumberLabel,
    reference: invoiceDetailsCustomPayProps.reference,
    bicLabel: invoiceDetailsCustomPayProps.bicLabel,
    bic: invoiceDetailsCustomPayProps.bic
  }

  return (
    <InvoiceDetailsPanel>
      <InvoiceDetailsCommon {...invoiceDetailsCommonProps} />
      <Total>
        <FormatAmount>{invoiceDetailsCustomPayProps.total}</FormatAmount> €{' '}
      </Total>
      <TotalLabel>{t('type25AmountToPayMinimum.label')}</TotalLabel>
      {!!invoiceDetailsCustomPayProps.totalMax && (
        <>
          <Divider />
          <InvoiceRow size="small">
            <label>
              {t(invoiceDetailsCustomPayProps.totalCreditLabel || 'ontimeTotalCredit.label')}
            </label>
            <span>
              <FormatAmount>{invoiceDetailsCustomPayProps.totalMax}</FormatAmount> €
            </span>
          </InvoiceRow>
          {!invoiceDetailsCustomPayProps.noPdf && (
            <>
              <InvoiceRow size="small">
                <label>
                  {t(invoiceDetailsCustomPayProps.pdfLinkLabel || 'ontimeAsPdfButton.label')}
                </label>
                <span>
                  <PdfButtonIcon
                    handleOnShowPdf={invoiceDetailsCustomPayProps.onShowPdf}
                    isAuthenticated={isAuthenticated}
                  />
                </span>
              </InvoiceRow>
              {!isAuthenticated && (
                <InvoiceRow size="small">
                  <DisclaimerLabel disclaimerText={t('pdfNotAuthenticated.label')} />
                </InvoiceRow>
              )}
            </>
          )}
        </>
      )}
    </InvoiceDetailsPanel>
  )
}

export default InvoiceDetailsCustomPay
