/**
 * This file contains various visual only (without any UI logic)
 * components for logged-out views (auth, auth error, logout success).
 */
import * as React from 'react'
import { LoggedOutLogoWrapper } from './loggedOutElements.style'
import { Logo } from '../../../common/styled'

export const LoggedOutLogo = (logo: Logo) => (
  <LoggedOutLogoWrapper>
    <img src={logo.image} alt={logo.alt} height={logo.height} />
  </LoggedOutLogoWrapper>
)
