import * as React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { clearMaintenance } from '../common/state/common'

type MaintenancePageConnectedProps = ConnectedProps<typeof connector>
class MaintenancePage extends React.Component<MaintenancePageConnectedProps> {
  componentDidMount() {
    this.props.clearMaintenance()
  }

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <h4>System maintenance ongoing - please try again later</h4>
        <h4>Systemunderhåll på gång - försök pånytt senare</h4>
      </div>
    )
  }
}

const connector = connect(() => ({}), { clearMaintenance })
export default connector(MaintenancePage)
