import styled from 'styled-components/macro'

export const SpinnerWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SpinnerWrapperText = styled.div`
  padding: 20px;
`
