import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { Interaction } from '../../../common/const'
import { setLanguage } from '../../../common/state/common'
import { RootState } from '../../../common/store'
import { logInteraction } from '../../../common/backend'
import { Label, Option, Wrapper } from './LanguageSelector.style'
import { getThemeFromState } from '../../../common/themes/theme'

type LanguageSelectorConnectedProps = ConnectedProps<typeof connector>
class LanguageSelector extends React.Component<
  LanguageSelectorConnectedProps & WithTranslation,
  { expanded: boolean }
> {
  constructor(props: LanguageSelectorConnectedProps & WithTranslation) {
    super(props)
    this.state = { expanded: false }
  }

  changeLanguage = (value: string) => {
    const { i18n } = this.props
    i18n.changeLanguage(value)
    this.props.setLanguage(value)
    this.setState({ expanded: false })
    // Matomo, change site ID, hardcode actual IDs
    const windowAny = window as any // bypass typechecking
    if (windowAny._paq) {
      windowAny._paq.push(['setSiteId', value === 'sv' ? '29' : '30'])
    }
    if (this.props.invoiceId) {
      logInteraction(this.props.invoiceId, Interaction.LANGUAGE_SWITCHED, { language: value }) // no await, fire and forget
    }
  }

  render() {
    const { i18n, t } = this.props
    const { expanded } = this.state
    const languages = getThemeFromState().languages
    const currLanguage = languages.includes(i18n.language) ? i18n.language : languages[0]

    return (
      <Wrapper>
        <Label>{t('chooseLanguage.label')}</Label>
        {languages.map((lang) => (
          <Option key={lang} $hidden={currLanguage !== lang && !expanded}>
            <button
              type="button"
              onClick={() =>
                expanded ? this.changeLanguage(lang) : this.setState({ expanded: true })
              }>
              <img width="16" height="16" alt={lang} src={`/img/${lang}.png`} />
            </button>
          </Option>
        ))}
      </Wrapper>
    )
  }
}

const connector = connect((state: RootState) => ({ invoiceId: state.invoice.invoiceId }), {
  setLanguage
})

export default connector(withTranslation()(LanguageSelector))
