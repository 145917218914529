import styled from 'styled-components/macro'

export const Container = styled.div`
  background: ${(props) => props.theme.panel};
  min-height: 100%;
  overflow: scroll;
  min-width: 360px;
`

export const Placeholder = styled.div`
  height: 504px;
  width: 360px;
  background-color: ${(props) => props.theme.panel};
`
