import styled from 'styled-components/macro'

export const Link = styled.div`
  & a {
    color: ${(props) => props.theme.link};
    font-size: 13px;
    margin: 0 10px 2px;
    white-space: nowrap;
    text-decoration: none;
    border-bottom: 1px solid ${(props) => props.theme.link};
    padding-bottom: 2px;
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 22px;
  flex-shrink: 0;
  font-weight: 600;
`

export const Footer = styled.div`
  color: ${(props) => props.theme.text};
  margin: 30px 00px 2px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  text-align: left;
`
