import styled from 'styled-components/macro'

export const Input = styled.div<{ valid?: boolean }>`
  border: ${(props) => (props.valid ? '1px solid #616161' : '1px solid #F00')};
  color: ${(props) => (props.valid ? props.theme.text : '#F00')};
  border-radius: 10px;
  padding: 10px 20px;
  margin: 15px 0;

  & label {
    font-size: 12px;
    line-height: 22px;
  }

  & input {
    border: none;
    padding: 0;
    font-size: 16px;
    line-height: 26px;
    width: 100%;
    outline: none;
  }
`
