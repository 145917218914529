import React from 'react'
import { StyledSVGContainer, StyledSVGPath } from './Icon.style'
import { IconSizeProps } from '../../common/const'

interface SVGProps {
  svgSource: string
  color: string
  size: IconSizeProps
}

const SvgComponent = ({ svgSource, color, size }: SVGProps) => {
  return (
    <StyledSVGContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19 11"
        width={size.width}
        height={size.height}>
        <StyledSVGPath color={color} d={svgSource} />
      </svg>
    </StyledSVGContainer>
  )
}

export default SvgComponent
