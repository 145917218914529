import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Invoice from '../../../common/types/Invoice'
import { InvoiceDetailsPanel, InvoiceRow } from '../common/invoiceElements'

interface InvoicePayerDetailsProps {
  invoice: Invoice
}

const InvoicePayerDetails = ({ invoice }: InvoicePayerDetailsProps) => {
  const { t } = useTranslation()

  return (
    <InvoiceDetailsPanel>
      <h3>{t('borrower.label')}</h3>
      <InvoiceRow size="big">
        <label>
          {invoice.recipientDetails.firstName} {invoice.recipientDetails.lastName}
          <br />
          {invoice.recipientDetails.address}
          <br />
          {invoice.recipientDetails.zipCode} {invoice.recipientDetails.postOffice}
        </label>
      </InvoiceRow>
    </InvoiceDetailsPanel>
  )
}

export default InvoicePayerDetails
