import styled from 'styled-components/macro'

export const Disclaimer = styled.div<{ margin?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : '0 0 15px 0')};

  img {
    width: 22px;
    margin-right: 5px;
  }
  p {
    font-size: 12px;
    text-align: center;
    margin: 0;
  }
`
