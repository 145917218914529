import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Banner, BannerText } from './NotProdBanner.style'

const NotProdBanner = () => {
  const { t } = useTranslation()
  const isProd = process.env.REACT_APP_ENV === 'production'

  return !isProd ? (
    <Banner>
      <BannerText>{t('notProdBanner.label')}</BannerText>
    </Banner>
  ) : (
    <></>
  )
}

export default NotProdBanner
