import * as React from 'react'
import Invoice from '../../../common/types/Invoice'
import TemplateConfiguration from '../../../common/types/TemplateConfiguration'
import { InvoiceDetailsCommonProps } from '../InvoiceDetailsTypes/InvoiceDetailsCommon'
import InvoiceDetailsMinPay, {
  InvoiceDetailsMinPayProps
} from '../InvoiceDetailsTypes/InvoiceDetailsMinPay'
import InvoiceDetailsCustomPay, {
  InvoiceDetailsCustomPayProps
} from '../InvoiceDetailsTypes/InvoiceDetailsCustomPay'
import InvoiceDetailsGeneral, {
  InvoiceDetailsGeneralProps
} from '../InvoiceDetailsTypes/InvoiceDetailsGeneral'
import InvoiceDetailsSerbia, {
  InvoiceDetailsSerbiaProps
} from '../InvoiceDetailsTypes/InvoiceDetailsSerbia'

interface InvoiceDetailsProps {
  invoice: Invoice
  templateConfiguration: TemplateConfiguration
  onShowPdf: () => void
  isAuthenticated: boolean
}

const InvoiceDetailsDefaultView = ({
  invoice,
  templateConfiguration,
  onShowPdf,
  isAuthenticated
}: InvoiceDetailsProps) => {
  const uiOptions = templateConfiguration.uiOptions

  const invoiceCommonDetailsProps: InvoiceDetailsCommonProps = {
    detailsLabel: uiOptions.detailsLabel,
    dueDateLabel: uiOptions.dueDateLabel,
    dueDate: invoice.invoiceDetails.dueDate,
    recipientLabel: uiOptions.recipientLabel,
    detailsAllowCopy: uiOptions.detailsAllowCopy,
    companyName: invoice.invoicerDetails.companyName,
    accountNumberLabel: uiOptions.accountNumberLabel,
    iban: invoice.invoiceDetails.iban,
    referenceNumberLabel: uiOptions.referenceNumberLabel,
    reference: invoice.invoiceDetails.reference,
    bicLabel: uiOptions.bicLabel,
    bic: invoice.invoiceDetails.bic
  }

  const invoiceDetailsGeneral: InvoiceDetailsGeneralProps = {
    ...invoiceCommonDetailsProps,
    total: invoice.invoiceDetails.total,
    totalMax: invoice.invoiceDetails.totalMax,
    openAmount: invoice.invoiceDetails.openAmount,
    totalCreditLabel: uiOptions.totalCreditLabel,
    noPdf: uiOptions.noPdf,
    pdfLinkLabel: uiOptions.pdfLinkLabel,
    onShowPdf: onShowPdf,
    isAuthenticated: isAuthenticated
  }

  if (uiOptions.isMinimumAmount) {
    const invoiceDetailsMinPayProps: InvoiceDetailsMinPayProps = {
      ...invoiceCommonDetailsProps,
      total: invoice.invoiceDetails.total,
      minimumAmount: invoice.invoiceDetails.minimumAmount,
      openAmount: invoice.invoiceDetails.openAmount,
      totalCreditLabel: uiOptions.totalCreditLabel,
      noPdf: uiOptions.noPdf,
      pdfLinkLabel: uiOptions.pdfLinkLabel,
      onShowPdf: onShowPdf,
      isAuthenticated: isAuthenticated
    }
    // Check whether the invoice total amount is less than minimum amount
    if (invoiceDetailsMinPayProps.minimumAmount < invoiceDetailsMinPayProps.total) {
      return <InvoiceDetailsMinPay {...invoiceDetailsMinPayProps} />
    }

    // If the invoice total amount is less than minimum amount render the general invoice view
    return <InvoiceDetailsGeneral {...invoiceDetailsGeneral} />
  }

  if (uiOptions.customAmount) {
    const invoiceDetailsCustomPayProps: InvoiceDetailsCustomPayProps = {
      ...invoiceCommonDetailsProps,
      total: invoice.invoiceDetails.total,
      totalMax: invoice.invoiceDetails.totalMax,
      totalCreditLabel: uiOptions.totalCreditLabel,
      noPdf: uiOptions.noPdf,
      pdfLinkLabel: uiOptions.pdfLinkLabel,
      onShowPdf: onShowPdf,
      isAuthenticated: isAuthenticated
    }
    return <InvoiceDetailsCustomPay {...invoiceDetailsCustomPayProps} />
  }
  return <InvoiceDetailsGeneral {...invoiceDetailsGeneral} />
}

const InvoiceDetailsSerbiaView = ({ invoice, templateConfiguration }: InvoiceDetailsProps) => {
  const uiOptions = templateConfiguration.uiOptions
  const invoiceDetailsSerbiaProps: InvoiceDetailsSerbiaProps = {
    detailsLabel: uiOptions.detailsLabel,
    recipientLabel: uiOptions.recipientLabel,
    companyName: invoice.invoicerDetails.companyName,
    detailsAllowCopy: uiOptions.detailsAllowCopy,
    bankLabel: uiOptions.bankLabel,
    bank: invoice.invoiceDetails.bank,
    accountNumberLabel: uiOptions.accountNumberLabel,
    iban: invoice.invoiceDetails.iban,
    referenceNumberLabel: uiOptions.referenceNumberLabel,
    reference: invoice.invoiceDetails.reference,
    total: invoice.invoiceDetails.total,
    currency: uiOptions.currency,
    amountToPayLabel: uiOptions.amountToPayLabel,
    dueDate: invoice.invoiceDetails.dueDate,
    dueDateLabel: uiOptions.dueDateLabel
  }

  return <InvoiceDetailsSerbia {...invoiceDetailsSerbiaProps} />
}

const InvoiceDetails = (props: InvoiceDetailsProps) => {
  const uiOptions = props.templateConfiguration.uiOptions
  const layout = (uiOptions || {}).detailsLayout
  switch (layout) {
    case 'serbia':
      return <InvoiceDetailsSerbiaView {...props} />
    default:
      return <InvoiceDetailsDefaultView {...props} />
  }
}

export default InvoiceDetails
