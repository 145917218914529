import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import { PartialState } from './store'
import { getThemePrimaryLanguage } from './themes/theme'

const getLangFromLocalStorage = () => {
  const state = localStorage.getItem('state')
  if (state) {
    const stateJson = JSON.parse(state) as PartialState
    if (stateJson && stateJson.common) {
      return stateJson.common.language
    }
  }
  return
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      /* translation file path */
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json?random=' + new Date().getTime()
    },
    lng: getLangFromLocalStorage(),
    fallbackLng: getThemePrimaryLanguage(),
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      useSuspense: true
    }
  })

export default i18n
