import * as React from 'react'
import InvoiceLayout from '../components/InvoiceLayout/InvoiceLayout.component'
import LinkExpired from '../components/LinkExpired/LinkExpired.component'

const LinkExpiredView = () => {
  return (
    <InvoiceLayout>
      <LinkExpired />
    </InvoiceLayout>
  )
}

export default LinkExpiredView
