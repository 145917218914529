import React from 'react'
import { GoBackButtonAuth } from '../../../components/buttons'
import { useTranslation } from 'react-i18next'
import { BackwardArrowIcon } from '../../../components/ButtonIcon.component/IconSvg'
import { IconSize, IconColor } from '../../../common/const'

const GoBackButton = ({ invoiceId }: { invoiceId: string | null }) => {
  const { t } = useTranslation()

  const handleClick = () => {
    if (invoiceId) {
      window.location.href = `/${invoiceId}`
    }
  }

  return (
    <GoBackButtonAuth onClick={handleClick}>
      <BackwardArrowIcon color={IconColor.PRIMARY} size={IconSize.SMALL} />
      {t('goBackButton.label')}
    </GoBackButtonAuth>
  )
}

export default GoBackButton
