import styled from 'styled-components/macro'

export const Banner = styled.div`
  top: 0;
  width: 100%;
  background-color: #ff3838;
  text-align: center;
`

export const BannerText = styled.div`
  color: white;
  padding: 12px;
`
