import styled from 'styled-components/macro'
import { css } from 'styled-components/macro'

export const Wrapper = styled.div`
  text-align: right;
  color: ${(props) => props.theme.link};
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  text-decoration: none;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const Label = styled.div`
  margin-right: 10px;
  line-height: 24px;
`

export const Option = styled.div<{ $hidden: boolean }>`
  line-height: 16px;
  margin: 0 10px;
  transition: opacity 0.5s;

  & button {
    background: none;
    border: none;
    padding: 0;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    vertical-align: middle;
  }

  ${(props) =>
    props.$hidden &&
    css`
      margin: 0;
      width: 0;
      opacity: 0;
      visibility: hidden;
    `}
`
