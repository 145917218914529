import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButtonAlternate, SecondaryButton } from '../../../components/buttons'
import { IconColor, IconSize, InvoiceSubpage, PayStatus } from '../../../common/const'
import { File2Icon } from './icons'
import Spinner from './Spinner'
import CustomAmountPopup from '../CustomAmountPopup/CustomAmountPopup.component'
import MinimumAmountConfirmationPopup from '../MinimumAmountConfirmationPopup/MinimumAmountConfirmationPopup.component'
import PayButton from './PayButton'
import { ForwardArrowIcon } from '../../../components/ButtonIcon.component/IconSvg'

export interface PaymentButtonGroupProps {
  layout: 'default' | 'serbia' | undefined
  status: PayStatus | null
  isCustomAmount?: boolean
  isMinimumAmount?: boolean
  customAmount: string | number | null
  minimumAmount: number
  total: number
  totalMax: number
  noPdf?: boolean
  payButtonLabel?: string
  isCustomAmountPopupOpen: boolean
  isMinimumAmountPopupOpen: boolean
  moreDetails?: boolean
  pdfButtonLabel?: string
  onSetShowPdf: (showPdf: boolean) => void
  setSubPage: (subpage: InvoiceSubpage) => void
  onPay: (amount: string | number | null) => void
  onSetCustomAmount: (amount: string | number) => void
}

/**
The following buttons can be displayed under invoice details:
    * Pay (primary): show always if paymentProvider is set
    * Custom amount: show if detailsLayout is set to 'default' and customAmount UI option is true
    * Minimum amount: show if detailsLayout is set to 'default' and minimumAmount UI option is true
    * PDF: show if noPdf UI option is NOT true (so default is show) and if customAmount UI option is NOT true
        (with custom amount PDF button is shown elsewhere).
        If paymentProvider is not set, this button is shown as primary (as there is no primary pay button in this case)
    * Details: show if moreDetails UI button is set
**/

const PaymentButtonGroup = ({
  layout,
  status,
  isCustomAmount,
  isMinimumAmount,
  customAmount,
  minimumAmount,
  total,
  totalMax,
  noPdf,
  payButtonLabel,
  isCustomAmountPopupOpen,
  isMinimumAmountPopupOpen,
  moreDetails,
  pdfButtonLabel,
  onSetShowPdf,
  setSubPage,
  onPay,
  onSetCustomAmount
}: PaymentButtonGroupProps) => {
  const { t } = useTranslation()

  // Serbia button group layout rendering
  function renderSerbiaLayoutButtonGroup() {
    return (
      <PrimaryButtonAlternate
        disabled={status === PayStatus.LOADING}
        onClick={() => onSetShowPdf(true)}>
        {t(pdfButtonLabel || 'ontimePdfButton.label')}&nbsp;
        <File2Icon alt="PDF" />
      </PrimaryButtonAlternate>
    )
  }

  // Default button group layout rendering
  function renderDefaultLayoutButtonGroups() {
    // If in ui options customAmount is set to true
    if (isCustomAmount) {
      return renderCustomAmountButtonGroup()
    }

    // If in ui options, minimumAmount is set to true
    if (isMinimumAmount) {
      return renderPayMinimumAmountButtonGroup()
    }

    // If either customAmount or minimumAmount is not set to true in ui options, will render default pay button layout
    return renderGeneralPayButtonGroup()
  }

  // Rendering minimum amount payment button group
  function renderPayMinimumAmountButtonGroup() {
    // If minimum amount is greater than invoice amount it should render the minimum payment button layout
    if (minimumAmount < total) {
      return (
        <>
          <PrimaryButtonAlternate
            disabled={status === PayStatus.LOADING}
            onClick={() => setSubPage(InvoiceSubpage.MINIMUM_AMOUNT_CONFIRMATION_POPUP)}>
            {t('ontimePayMinimumAmountButton.label')}
            <ForwardArrowIcon color={IconColor.SECONDARY} size={IconSize.SMALL} />
          </PrimaryButtonAlternate>
          <SecondaryButton onClick={() => onPay(total)} disabled={status === PayStatus.LOADING}>
            {status === PayStatus.LOADING ? (
              <Spinner />
            ) : (
              <>
                {t(payButtonLabel || 'ontimePayInvoiceButton.label')}
                <ForwardArrowIcon color={IconColor.PRIMARY} size={IconSize.SMALL} />
              </>
            )}
          </SecondaryButton>
          <MinimumAmountConfirmationPopup
            isOpen={isMinimumAmountPopupOpen}
            isLoading={status === PayStatus.LOADING}
            onPay={() => onPay(minimumAmount)}
            onCancel={() => setSubPage(InvoiceSubpage.DEFAULT)}
          />
        </>
      )
    }

    // If minimum amount is less than invoice amount it should render the default payment button layout
    return renderGeneralPayButtonGroup()
  }

  // Rendering custom amount payment button group
  function renderCustomAmountButtonGroup() {
    return (
      <>
        <PrimaryButtonAlternate
          onClick={() => onPay(total)}
          disabled={status === PayStatus.LOADING}>
          {status === PayStatus.LOADING ? (
            <Spinner />
          ) : (
            <>
              {t(payButtonLabel || 'ontimePayInvoiceButton.label')}
              <ForwardArrowIcon color={IconColor.SECONDARY} size={IconSize.SMALL} />
            </>
          )}
        </PrimaryButtonAlternate>
        <SecondaryButton
          disabled={status === PayStatus.LOADING}
          onClick={() => setSubPage(InvoiceSubpage.CUSTOM_AMOUNT_POPUP)}>
          {t('ontimePayAnotherAmount.label')}
          <ForwardArrowIcon color={IconColor.PRIMARY} size={IconSize.SMALL} />
        </SecondaryButton>
        <CustomAmountPopup
          isOpen={isCustomAmountPopupOpen}
          payButtonLabel={payButtonLabel}
          customAmount={customAmount || ''}
          setCustomAmount={(amount: string) => onSetCustomAmount(amount)}
          minAmount={total}
          maxAmount={totalMax}
          isLoading={status === PayStatus.LOADING}
          onPay={() => onPay(customAmount)}
          onCancel={() => setSubPage(InvoiceSubpage.DEFAULT)}
        />
      </>
    )
  }

  // Rendering default payment button group
  function renderGeneralPayButtonGroup() {
    return (
      <>
        <PayButton
          handleOnPay={() => onPay(total)}
          isDisabled={status === PayStatus.LOADING}
          label={payButtonLabel}
        />
        {!noPdf && !isMinimumAmount && !isCustomAmount && renderPdfButton()}
        {moreDetails && renderMoreDetailsButton()}
      </>
    )
  }

  // Rendering pdf button
  function renderPdfButton() {
    return (
      <SecondaryButton disabled={status === PayStatus.LOADING} onClick={() => onSetShowPdf(true)}>
        {t(pdfButtonLabel || 'ontimePdfButton.label')}
        <ForwardArrowIcon color={IconColor.PRIMARY} size={IconSize.SMALL} />
      </SecondaryButton>
    )
  }

  // Rendering more details button
  function renderMoreDetailsButton() {
    return (
      <SecondaryButton
        disabled={status === PayStatus.LOADING}
        onClick={() => setSubPage(InvoiceSubpage.DETAILS)}>
        {t('moreDetailedInformation.label')}
        <ForwardArrowIcon color={IconColor.PRIMARY} size={IconSize.SMALL} />
      </SecondaryButton>
    )
  }

  // Conditional rendering based on layouts
  switch (layout) {
    case 'serbia':
      // Serbia layout
      return renderSerbiaLayoutButtonGroup()
    default:
      // Default layout
      return renderDefaultLayoutButtonGroups()
  }
}

export default PaymentButtonGroup
