import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Wrapper } from './NoticeIfPaid.style'

const NoticeIfPaid = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <img src="/img/info.svg" alt="" />
      <div>{t('ifAlreadyPaid.label')}</div>
    </Wrapper>
  )
}

export default NoticeIfPaid
